/* eslint-disable func-names */
import React, { useEffect, useState } from 'react';

export function Thumbnailer(props: { file: File; thumbnailHandler?: (f: File) => void }) {
  const QUALITY = 0.25; // Between 0 - 1
  const { file, thumbnailHandler } = props;
  const [imgToPreview, setImgToPreview] = useState<string>('');
  const fr = new FileReader();
  fr.onloadend = () => setImgToPreview(`${fr.result}`);
  const [thumbnail, setThumbnail] = useState<string>();

  useEffect(() => {
    fr.readAsDataURL(file);
  }, [file, fr]);

  useEffect(() => {
    const image = new Image();
    image.setAttribute('crossOrigin', 'anonymous');

    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width * QUALITY;
      canvas.height = image.height * QUALITY;
      canvas
        .getContext('2d')
        ?.drawImage(image, 0, 0, image.width * QUALITY, image.height * QUALITY);

      setThumbnail(canvas.toDataURL('image/png', QUALITY));

      const blobCB = function (blob: Blob | null) {
        // ALERT: Keep this function synced on both portal and API if you change it
        const filenameNoExtension = file.name.replace(/\.[^/.]+$/, '');

        const tempFile = new File([blob || ''], `${filenameNoExtension}-thumbnail.png`, {
          type: 'image/png',
        });

        thumbnailHandler?.(tempFile);
      };
      // eslint-disable-next-line prefer-arrow-callback
      canvas.toBlob(blobCB, 'image/png', QUALITY);
    };

    image.src = imgToPreview;
  }, [imgToPreview]);

  if (!file || !/image/.test(file.type)) return null;
  return <img src={thumbnail} alt={file.name} height="100" width="auto" />;
}
