import Grid from '@mui/material/Grid2';
import React from 'react';
import { SterlingButton } from './SterlingButton';
import { ButtonGroupWrapper, ButtonAndLabelWrapper } from './styled.SterlingButtons';

interface SterlingButtonValues {
  label: string;
  value: string;
}

interface SterlingButtonGroupProps {
  currentValue: string;
  buttonValues: SterlingButtonValues[];
  setFunction: (arg: string) => void;
  className?: string;
  label?: string;
  style?: React.CSSProperties;
}

export function SterlingButtonGroup(props: SterlingButtonGroupProps) {
  const { currentValue, buttonValues, setFunction, style, label } = props;

  return (
    <ButtonAndLabelWrapper>
      {/* eslint-disable-next-line */}
      <label className="button-group-label">{label}</label>
      <ButtonGroupWrapper >
        {buttonValues.map((button, index) => (
          <Grid key={index} size="auto">
            <SterlingButton
              name="select-category-button"
              className={props.className}
              style={style}
              onClick={() => setFunction(button.value)}
              active={button.value === currentValue}
              buttonGroup
            >
              {button.label}
            </SterlingButton>
          </Grid>
        ))}
      </ButtonGroupWrapper>
    </ButtonAndLabelWrapper>
  );
}
