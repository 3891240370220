import React, { useEffect } from 'react';
import { useAdherenceResourcesQuery } from 'generated';
import { useImageModal } from 'components';
import { addSlashes, CurrentOem } from 'utility';
import { useNavigate, useLocation } from 'react-router-dom';
import { bindAccordianEvents } from 'components/general/accordian/Accordian';
import { Analytics } from 'components/util/AnalyticEvents';
import { Download } from 'components/general/Download';
import { useLanguage } from 'components/util/Language';

export const useResourcesModel = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const lang = useLanguage();

  const oemClassName = `content guide ${CurrentOem.toLowerCase()}`;
  const { onImageClick, ImageModal } = useImageModal();
  const resourceQuery = useAdherenceResourcesQuery({
    variables: { path: addSlashes(location.pathname), language: lang },
  });

  const handleClick = (e: React.SyntheticEvent) => {
    const targetLink = e.target as HTMLAnchorElement & HTMLImageElement;
    if(targetLink?.hash?.startsWith('#')) return;
    if (targetLink && targetLink.nodeName.toLowerCase() === 'a') {
      if (targetLink.href.includes('mailto')) {
        e.preventDefault();
        navigate(targetLink.href);
        return;
      }

      if (targetLink.href.includes('.zip') || targetLink.href.includes('.pdf')) {
        e.preventDefault();
        Analytics.Download(targetLink.text, targetLink.href);
        Download({ url: targetLink.href });
        return;
      }
      if (
        targetLink.href.includes('blvdagency') ||
        targetLink.href.includes('controlcentre') ||
        targetLink.href.includes('localhost')
      ) {
        e.preventDefault();
        // eslint-disable-next-line
        Analytics.LinkClick(targetLink.text, targetLink.href);
        navigate(targetLink.href.replace(/^(?:\/\/|[^/]+)*\//, '/'));
      }
    } else if (targetLink && targetLink.nodeName.toLowerCase() === 'img') {
      if (targetLink.parentElement?.nodeName.toLowerCase() === 'a') {
        // do nothing, it's a zip file
        e.preventDefault();
        Analytics.Download(targetLink.src, targetLink.href);
        Download({ url: (targetLink.parentNode as HTMLAnchorElement).href });
      } else {
        Analytics.LinkClick(targetLink.src, 'Image Modal');
        onImageClick(e);
      }
    }
  };

  // Scroll to top whenever content changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [resourceQuery.data]);

  useEffect(() => {
    bindAccordianEvents();
  }, [resourceQuery.data]);

  return {
    oemClassName,
    ImageModal,
    handleClick,
    resourceContent:
      resourceQuery?.data?.adherenceContentOne?.content ?? 'Resource page was not found.',
    next: resourceQuery.data?.adherenceContentOne?.next?.noContent
      ? resourceQuery.data?.adherenceContentOne?.next?.children?.[0]
      : resourceQuery.data?.adherenceContentOne?.next,
    previous: resourceQuery.data?.adherenceContentOne?.previous?.noContent
      ? resourceQuery.data?.adherenceContentOne?.previous?.children?.[0]
      : resourceQuery.data?.adherenceContentOne?.previous,
    resourceQuery,
  };
};
