import Grid from '@mui/material/Grid2';
import { PropsFor } from '@mui/system';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import React, { ComponentProps, Dispatch, SetStateAction } from 'react';
import { StSwitch } from 'suites/sterling/molecules/inputs/switch/Switch';
import { enUS, frFR } from '@mui/x-data-grid/locales';
import { useLanguage } from 'components/util/Language';

interface ToolbarProps {
  title?: string;
  tableTitle?: React.ReactNode;
  enableShowChange?: boolean;
  disableExport?: boolean;
  customSelectionButtons?: React.ReactNode;
}
interface ShowChangeProps {
  showChange?: boolean;
  setShowChange?: Dispatch<SetStateAction<boolean>>;
}

function ToolbarButtons({
  title,
  disableExport,
  ...rest
}: ToolbarProps & PropsFor<typeof Grid>) {
  return (
    <Grid {...rest}>
      <GridToolbarFilterButton
        slotProps={{
          button: {
            sx: {
              marginRight: disableExport ? 0 : 10,
              '.MuiSvgIcon-root': {
                width: '1rem',
                height: '1rem',
              },
            },
          },
        }}
      />
      {!disableExport && (
        <GridToolbarExport
          csvOptions={{ fileName: title }}
          slotProps={{ button: { style: { lineHeight: 1.7 } } }}
        />
      )}
    </Grid>
  );
}
function ShowChangeSwitch({ showChange = false, setShowChange }: ShowChangeProps) {
  // eslint-disable-next-line
  const showChangeCallback = setShowChange || console.log;
  return (
    <Grid justifySelf="flex-end">
      <StSwitch
        enabled={showChange}
        toggle={() => showChangeCallback(!showChange)}
        label="Show Change"
      />
    </Grid>
  );
}
function Toolbar({
  showChange,
  setShowChange,
  title,
  enableShowChange,
  tableTitle,
  disableExport,
  customSelectionButtons,
}: ToolbarProps & ShowChangeProps) {
  return (
    <GridToolbarContainer>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
        sx={{ width: '100%', padding: 0.5 }}
      >
        {enableShowChange ? (
          <Grid container justifyContent="space-between" size={12}>
            <ToolbarButtons {...{ title, disableExport }} size="grow" />
            <ShowChangeSwitch {...{ showChange, setShowChange }} />
          </Grid>
        ) : customSelectionButtons !== undefined && customSelectionButtons ? (
          <Grid container justifyContent="space-between" alignItems="center" size={12}>
            <Grid size={12}>{tableTitle && tableTitle}</Grid>
            <Grid container mt={4} mb={1} justifyContent="space-between" size={12}>
              {customSelectionButtons}
              <Grid alignItems="flex-end" sx={{ display: 'flex' }}>
                <ToolbarButtons
                  {...{ title, disableExport }}
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent="space-between" alignItems="center" size={12}>
            {tableTitle && tableTitle}
            <Grid alignItems="flex-end" sx={{ display: 'flex' }}>
              <ToolbarButtons
                {...{ title, disableExport }}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </GridToolbarContainer>
  );
}

export interface StDataGridProps
  extends ComponentProps<typeof DataGrid>,
    ToolbarProps,
    ShowChangeProps {
  noToolbar?: boolean;
  noFooter?: boolean;
}

function ColumnResize() {
  return <> </>;
}

export function StDataGrid({
  noToolbar,
  showChange = false,
  enableShowChange = false,
  title = 'Centre of Excellence',
  setShowChange = () => undefined,
  rows,
  tableTitle,
  disableExport,
  customSelectionButtons,
  ...props
}: StDataGridProps) {
  const lang = useLanguage();
  const DataGridToolbar = React.useCallback(
    () => (
      <Toolbar
        {...{
          title,
          showChange,
          setShowChange,
          disableExport,
          customSelectionButtons,
          enableShowChange,
          tableTitle,
        }}
      />
    ),
    [showChange, setShowChange, title, enableShowChange, tableTitle]
  );
  const paginationSize = props.initialState?.pagination?.paginationModel?.pageSize;
  const hasFooter = rows?.length
    ? paginationSize
      ? rows?.length < paginationSize
      : rows?.length > 25
    : false;
  const dataGridLocale = { EN: enUS, FR: frFR }[lang];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <DataGrid
        slots={{
          toolbar: noToolbar ? undefined : DataGridToolbar,
          columnResizeIcon: ColumnResize,
        }}
        hideFooter={hasFooter}
        localeText={dataGridLocale.components.MuiDataGrid.defaultProps.localeText}
        slotProps={{
          filterPanel: {
            sx: {
              background: 'white',
              '& .MuiInput-input[type=text]': { padding: '8px 0 10px !important' },
              '& .MuiDataGrid-filterFormOperatorInput': { minWidth: '10rem' },
            },
          },
          baseButton: { variant: 'text' },
          pagination: {
            sx: { '.MuiTablePagination-toolbar': { borderBottom: 'none !important' } },
          },
        }}
        sx={{
          border: 'none',
          '.MuiDataGrid-withBorder': { borderRight: 'none' },
          '.MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
        }}
        rows={rows}
        {...props}
      />
    </div>
  );
}
