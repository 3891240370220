import { Navigate, Route, Routes, useResolvedPath } from 'react-router-dom';
import { ComplianceLanding } from 'suites/compliance/pages/landing/ComplianceLanding';
import { ComplianceSubmitReport } from 'suites/compliance/pages/submit-report/ComplianceSubmitReport';
import { ComplianceReviewCases } from 'suites/compliance/pages/review/ComplianceReviewCases';
import { ComplianceSupport } from 'suites/compliance/pages/support/ComplianceSupport';
import { useScrollToTop } from 'suites/sterling/app/hooks/useScrollToTop';
import { StDrawerProps } from 'suites/sterling/components/menus/Menu';
import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';
import { getToken } from 'utility';
import { ComplianceRoleController } from 'suites/compliance/components/controllers/role/ComplianceRoleController';
import { ComplianceRestrictedRoute } from 'suites/compliance/pages/restricted/RestrictedRoute';
import { ComplianceAdminPanel } from 'suites/compliance/pages/admin/ComplianceAdminPanel';
import { canViewAdminPanel } from '../business-logic';

const enableSubmitReportLanding =
  process.env.REACT_APP_COMPLIANCE_ENABLE_SUBMIT_REPORT_LANDING === 'true';
const enableReviewCases = process.env.REACT_APP_COMPLIANCE_ENABLE_REVIEW_CASES === 'true';
const enableSupport = process.env.REACT_APP_COMPLIANCE_ENABLE_SUPPORT === 'true';
const enableAdmin = process.env.REACT_APP_COMPLIANCE_ENABLE_ADMIN_PANEL === 'true';

export const generateComplianceMenuProps = (): StDrawerProps => {
  const token = getToken();
  const role = token?.compliance_role;

  if (!role) return { options: [] };

  const menuProps = {
    options: (
      [
        {
          label: c.pages.landing.menuLabel,
          to: '/',
        },
        {
          label: c.pages.submitReport.landing.menuLabel,
          to: '/report',
        },
        {
          label: c.pages.reviewCases.landing.menuLabel,
          to: '/review',
        },
        {
          label: c.pages.support.menuLabel,
          to: '/support',
        },
        {
          label: c.pages.adminPanel.menuLabel,
          to: '/admin',
        },
      ] as const
    ).filter((op) => {
      // Remove the /report option from the menu for non-dealers
      if (op.to === '/report' && role !== 'DEALER') {
        return false;
      }
      if (op.to === '/report' && enableSubmitReportLanding === false) {
        return false;
      }
      if (op.to === '/review' && enableReviewCases === false) {
        return false;
      }
      if (op.to === '/support' && enableSupport === false) {
        return false;
      }
      if (op.to === '/admin') {
        if (enableAdmin === false) {
          return false;
        }
        if (canViewAdminPanel(role) === false) {
          return false;
        }
      }

      return true;
    }),
  };

  return menuProps;
};

export function ComplianceRoutes() {
  useScrollToTop();
  const matchPath = useResolvedPath('').pathname;

  return (
    <Routes>
      <Route path="/reportingtool(/+)" element={<Navigate to="/reportingtool" replace />} />
      <Route path={`${matchPath}/`}>
        <ComplianceLanding />
      </Route>
      {enableSubmitReportLanding && (
        <Route path={`${matchPath}/report`}>
          <ComplianceRoleController roles={['DEALER']} fallback={<ComplianceRestrictedRoute />}>
            <ComplianceSubmitReport />
          </ComplianceRoleController>
        </Route>
      )}
      {enableReviewCases && (
        <Route path={`${matchPath}/review`}>
          <ComplianceReviewCases />
        </Route>
      )}
      {enableSupport && (
        <Route path={`${matchPath}/support`}>
          <ComplianceSupport />
        </Route>
      )}
      {enableAdmin && (
        <Route path={`${matchPath}/admin`}>
          <ComplianceAdminPanel />
        </Route>
      )}
    </Routes>
  );
}
