import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import React, { useContext, useState } from 'react';
import Create from '@mui/icons-material/Create';
import { ComplianceReportContext } from '../../ComplianceSubmitReport.model';
import { StPageSection } from 'suites/sterling/molecules/sections/page/PageSection';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { Button } from 'components';
import {
  casesSortedAscendingById,
  formToCreateCase,
  goToFormAnchor,
  StrictComplianceCase,
} from 'suites/compliance/app/business-logic';
import {
  complianceAppContent as c,
  reportFormStateLabel,
} from 'suites/compliance/ComplianceApp.content';
import {
  Compliance_Cases,
  useComplianceCaseCreateOneMutation,
  useComplianceCasesManyQuery,
  useComplianceCaseUpdateCaseInformationMutation,
  useUnitsManyQuery,
} from 'generated';
import { toTitleCase } from 'utility';
import { useComplianceParams } from 'suites/compliance/app/hooks/useComplianceParams';
import { useScrollToTop } from 'suites/sterling/app/hooks/useScrollToTop';
import { Galleryer } from 'suites/sterling/molecules/widgets/dropzone/Galleryer';
import dayjs from 'dayjs';

interface ReportReviewTableRowProps {
  field: string;
  value: React.ReactNode;
  linkLabel: string;
  onClick: () => void;
}

function ReportReviewTableRow(props: ReportReviewTableRowProps) {
  const { field, value, linkLabel, onClick } = props;

  return (
    <Grid container direction="row" wrap="nowrap" component={Box} py={3} gap={2}>
      <Grid size={3}>
        {field}
      </Grid>
      <Grid sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} size={6}>
        {value}
      </Grid>
      <Grid size={3}>
        <Button variant="text" startIcon={<Create />} onClick={onClick}>
          {linkLabel}
        </Button>
      </Grid>
    </Grid>
  );
}

export function ReportReview() {
  useScrollToTop();
  const { form, dispatchReportAction, setNewCaseId } = useContext(ComplianceReportContext);
  const formState = form?.getValues();
  const t = useTranslation();
  const { caseId } = useComplianceParams();
  const [createCase] = useComplianceCaseCreateOneMutation();
  const [updateCase] = useComplianceCaseUpdateCaseInformationMutation();
  const { refetch } = useComplianceCasesManyQuery();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [thumbnailFiles, setThumbnailFiles] = useState<File[]>([]);

  const unitsQuery = useUnitsManyQuery();
  const dealers = unitsQuery.data?.unitsMany;

  const reportedDealerCode = formState?.reportedDealerCode;
  const reportedDealerName =
    dealers?.find((d) => reportedDealerCode && d.code === reportedDealerCode)?.name ?? '-';

  const caseType = caseId ? 'UPDATE' : 'NEW';

  if (!formState) {
    throw Error('This page is invalid without existing form state');
  }

  const addEvidence = async (recordId: string) => {
    if (recordId) {
      const formData = new FormData();

      formState.caseEvidence.forEach((evid) => {
        formData.append(evid.name, evid);
      });

      thumbnailFiles?.forEach((evid) => {
        formData.append(evid.name, evid);
      });

      formData.append('recordId', recordId);

      const uri =
        process.env.NODE_ENV === 'development'
          ? 'http://hac.localhost.io:4000/rest/compliance/post'
          : `${process.env.REACT_APP_SERVICES_PROXY}/rest/compliance/post`;

      fetch(uri, {
        method: 'POST',
        body: formData,
        credentials: 'include',
      }).then(() => {
        dispatchReportAction('SUBMIT_REPORT');
        refetch().then((response) => {
          const cases = response.data?.complianceCasesMany as
            | StrictComplianceCase[]
            | undefined;
          if (cases) {
            const numCases = cases.length;
            const newestCaseId = casesSortedAscendingById(cases)?.[numCases - 1]?.caseId;
            if (newestCaseId) {
              setNewCaseId?.(newestCaseId);
            }
          }
        });
        setDisableSubmit(false);
      });
    }
  };

  return (
    <StPageSection variant="paper" noTopPadding>
      <Stack direction="column" gap={10}>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          {t(c.pages.submitReport.review.title)}
        </Typography>

        <Box sx={{ maxWidth: { xs: '95%', md: '70%' } }}>
          <Typography variant="body1">{t(c.pages.submitReport.review.subtitle)}</Typography>
        </Box>

        <Grid container direction="column" mb={10}>
          {Object.entries(formState).map(
            ([key, value]: [string, string | Date | File[]], idx) => {
              const typedKey = key as keyof typeof formState;

              let processedValue = Array.isArray(value) ? (
                <Galleryer
                  filesOrLinks={{ data: value }}
                  justifyContent="flex-start"
                  thumbnailHandler={(newThumb) => {
                    if (newThumb.type.includes('image/')) {
                      setThumbnailFiles?.((previousThumbs) => {
                        if (
                          // Don't allow duplicates
                          previousThumbs.some((thum) => {
                            return thum.name === newThumb.name;
                          })
                        ) {
                          return previousThumbs;
                        }
                        return [...previousThumbs, newThumb];
                      });
                    }
                  }}
                />
              ) : value instanceof Date ? (
                value.toString()
              ) : (
                value
              );

              if (processedValue instanceof dayjs) {
                processedValue = new Date(processedValue.toString()).toDateString();
              }

              if (typedKey === 'caseOtherCategory' && !processedValue) {
                return null;
              }

              const keyLabel = t(reportFormStateLabel(typedKey));

              return (
                <React.Fragment key={idx}>
                  <Grid
                    component={ReportReviewTableRow}
                    field={toTitleCase(keyLabel)}
                    value={processedValue}
                    linkLabel={`Edit ${keyLabel}`}
                    onClick={() => {
                      dispatchReportAction('BACK_TO_FORM');
                      goToFormAnchor(typedKey, caseId);
                    }} />
                  <Grid component={Divider} />
                </React.Fragment>
              );
            }
          )}
        </Grid>

        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {t(c.pages.submitReport.review.allCorrect.title)}
        </Typography>

        <Box sx={{ maxWidth: { xs: '95%', md: '70%' } }}>
          <Typography variant="body1">
            {t(c.pages.submitReport.review.allCorrect.subtitle)}
          </Typography>
        </Box>

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Button
            variant="text"
            onClick={() => {
              form?.reset();
              dispatchReportAction('BACK_TO_LANDING');
            }}
          >
            {t(c.pages.submitReport.review.button.labelCancel)}
          </Button>

          <Button
            disabled={disableSubmit}
            color="primary"
            onClick={async () => {
              setDisableSubmit(true);

              if (caseId) {
                const evidenceFilenames = formState.caseEvidence.map((evid) => ({
                  filename: evid.name,
                  mimetype: evid.type,
                }));

                await updateCase({
                  variables: {
                    caseId: caseId,
                    summary: formState.caseSummary,
                    ongoing: formState.caseOngoing,
                    category: formState.caseCategory,
                    categoryOther: formState.caseOtherCategory,
                    infractionDate: formState.caseDate,
                    infractionLocation: formState.caseLocation,
                    evidence: evidenceFilenames,
                  },
                }).then((response) => {
                  const recordId = response.data?.complianceCaseUpdateCaseInformation;
                  if (recordId) {
                    addEvidence(recordId);
                  }
                });
              } else {
                const newCase = formToCreateCase(formState, reportedDealerName);
                newCase.history.push({
                  modifiedDate: new Date().toUTCString(),
                  dealerNotes: undefined as unknown as string,
                  internalNotes: undefined as unknown as string,
                  internalNotesName: undefined as unknown as string,
                  status: 'SUBMITTED',
                });
                createCase({
                  variables: {
                    payload: newCase as Compliance_Cases,
                  },
                }).then((response) => {
                  const recordId = response.data?.complianceCaseCreateOne.recordId;
                  if (recordId) {
                    addEvidence(recordId);
                  }
                });
              }
            }}
          >
            {t(c.pages.submitReport.review.button.labelConfirm[caseType])}
          </Button>
        </Stack>
      </Stack>
    </StPageSection>
  );
}
