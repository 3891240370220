import { useLocation, useNavigate } from 'react-router-dom';
import { isFrenchURL, useLanguage } from 'suites/sterling/app/context/language';
import Language from '@mui/icons-material/Language';
import { SterlingButton } from 'components';

export function StLanguageToggle() {
  const lang = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const params = new URLSearchParams(location.search);

  return (
    <SterlingButton
      size="small"
      color="secondary"
      startIcon={<Language />}
      onClick={() => {
        const newURL = isFrenchURL.test(pathname)
          ? pathname.replace(/\/fr/, '')
          : pathname.replace(/^\//, '/fr/');

        navigate({ pathname: newURL, search: params.toString() });
      }}
    >
      {{EN: 'FR', FR: 'EN'}[lang]}
    </SterlingButton>
  );
}
