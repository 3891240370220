import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { OemTheme } from 'assets/styles/themes/functions';
import { colours } from 'assets/styles/themes/resources';
import { IE11Fix, screenSize } from 'assets/styles/themes/mixins';
import { materialGridStyles } from './styled.Grid.Resources';

export const modalStyle: CSSProperties = {
  top: `${50}%`,
  left: `${50}%`,
  transform: `translate(-${50}%, -${50}%)`,
  overflow: 'auto',
};

export const useModalStyles = makeStyles(() =>
  createStyles({
    paper: {
      backgroundColor: '#000;',
      position: 'absolute',
      width: '100%',
      border: '3px solid #000;',
      boxShadow: '10px',
      padding: 6,
      height: '100vh;',
    },
  })
);

const oemBaseFontStyles = css`
  text-align: left;
  color: ${colours.black};

  @media (max-width: 898px) {
    font-size: ${OemTheme.mobileFontSize.p};
  }
`;

const bufferClasses = css`
  &.buffer {
    margin-top: 10px;

    @media (max-width: 898px) {
      margin-top: 5px;
    }
  }

  &.buffer-top-sm {
    margin-top: 18px;

    @media (max-width: 898px) {
      margin-top: 10px;
    }
  }

  &.buffer-top-md {
    margin-top: 36px;

    @media (max-width: 898px) {
      margin-top: 18px;
    }
  }

  &.buffer-bottom {
    margin-bottom: 9px;

    @media (max-width: 898px) {
      margin-bottom: 5px;
    }
  }

  &.buffer-bottom-sm {
    margin-bottom: 18px;

    @media (max-width: 898px) {
      margin-bottom: 9px;
    }
  }

  &.buffer-bottom-md {
    margin-bottom: 27px;

    @media (max-width: 898px) {
      margin-bottom: 14px;
    }
  }
`;

const oemGuideH1 = css`
  ${OemTheme.tweaks('page_title_top_border_css')}
  font-family: ${OemTheme.font.headerBold};
  font-size: ${OemTheme.fontSize.h1};
  line-height: 1.15;
  text-align: left;
  color: ${colours.black};
  margin: ${OemTheme.tweaks('guide_header_margin', '85px 0 55px')};

  @media (max-width: 898px) {
    font-size: ${OemTheme.mobileFontSize.h1};
    margin: 0 0 31px;
  }
`;

const oemGuideH2 = css`
  ${bufferClasses};
  font-family: ${OemTheme.font.headerBold};
  font-size: ${OemTheme.fontSize.h2};
  line-height: 1.25;
  text-align: left;
  color: ${colours.black};
  margin: ${OemTheme.tweaks('guide_subheader_margin', '0 0 4px')};

  @media (max-width: 898px) {
    font-size: ${OemTheme.mobileFontSize.h2};
  }
`;

const oemGuideH3 = css`
  ${bufferClasses};
  display: block;
  font-family: ${OemTheme.font.headerMedium};
  font-size: ${OemTheme.fontSize.h3};
  line-height: 1.25;
  text-align: left;
  color: ${colours.black};
  margin: 0 0 4px;
  font-weight: normal;

  @media (max-width: 898px) {
    font-size: ${OemTheme.mobileFontSize.h3};
  }
`;

export const oemGuideIntroText = css`
  font-family: ${OemTheme.font.regular};
  font-size: 30px;
  line-height: 1.35;
  text-align: left;
  color: ${colours.black};
  margin-bottom: 63px;

  span,
  a {
    font-size: inherit;
  }

  @media (max-width: 898px) {
    font-size: 20px;
    margin: 0 0 31px;
  }
`;

export const oemGuideOlUlLists = css`
  padding: 0 0 0 20px;
  margin: 0 0 63px;

  ${OemTheme.tweaks('list_css')};

  @media (max-width: 898px) {
    margin-bottom: 31px !important;
  }

  &.download-link {
    padding: 6px 0 0 14px;
    margin: 0;
  }

  &.intro-text {
    margin-bottom: 18px !important;

    li {
      ${oemGuideIntroText};
      margin-bottom: 0 !important;
    }
  }

  li {
    font-size: 20px;
    margin-bottom: 15px;

    ol,
    ul {
      margin: 0;

      li {
        margin: 10px 0;
      }
    }

    a,
    span,
    strong {
      font-size: 20px;
    }
  }
`;

export const oemGuideStandardText = css`
  margin-top: 0;

  &.intro-text {
    ${oemGuideIntroText};
  }

  &.intro-text-multiple {
    ${oemGuideIntroText};
    margin-bottom: 18px;
  }

  &.intro-text-bold {
    ${oemGuideIntroText};
    margin-bottom: 18px;
    font-family: ${OemTheme.font.bold};
  }

  &.caption {
    font-size: 16px;
    margin: 0;

    @media (max-width: 898px) {
      font-size: 12px;
    }
  }

  &.courier {
    font-family: 'Courier New', Courier, monospace;

    @media (max-width: 898px) {
      font-size: 11px;
      word-break: break-all;
    }
  }
`;

export const oemGuideNewFlagClass = css`
  background-color: ${colours.black};
  color: ${colours.white};
  font-family: ${OemTheme.font.medium};
  font-weight: normal;
  font-size: 16px;
  width: 22px;
  padding: 2px 7px 3px;
  height: 11px;
  text-align: center;
  top: -4px;
  margin-left: 4px;
  position: relative;

  &.question {
    margin-left: 0;
    top: -2px;
    margin-right: 4px;
  }

  &.title {
    top: -10px;
  }
`;

const contentStyles = css`
  margin: 0 auto 100px;
  width: 95%;
  max-width: 1150px;
  outline: 0;

  @media (max-width: 898px) {
    width: 90%;
  }

  a {
    color: ${OemTheme.colour.text.anchor};
    /* text-decoration: none; */

    &:hover {
      text-decoration: underline;
    }
  }

  code {
    word-break: break-word;
  }

  strong {
    ${oemGuideH3}
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 36px 0 52px;
    cursor: pointer;

    @media all and (max-width: 1690px) {
      margin: 40px 0 26px;
    }
    @media all and (max-width: 900px) {
      margin-top: 18px;
    }

    &.border {
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  .sticky {
    position: fixed; /* added to support older browsers */
    position: sticky;
    top: 0;
    padding-left: 30px;
    font-size: '18px';

    @media (max-width: 898px) {
      padding-left: 0;
    }
  }

  ${OemTheme.tweaks('resourceTextPositioning')}

  /* &.wide {
    width: 1150px;

    @media all and (max-width: 1690px) {
      width: 95%;
    }
  } */

  &.guide {
    background-color: ${colours.white} !important;
    padding: 30px 30px 90px;
    margin-top: 100px;

    /* @media (max-width: 898px) {
      margin-top: 0;
      padding-top: 0;
    } */

    .grey-box {
      background-color: ${OemTheme.colour.background.secondary};
    }

    ${IE11Fix(css`
      .sticky {
        top: 25% !important;
      }
    `)}

    #content-resources {
      .MuiGrid2-item p:last-child {
        margin-bottom: 0;
      }

      hr {
        margin-bottom: 26px !important;
        margin-top: 0 !important;
      }
    }
  }

  .grey-box {
    background-color: #eeece9;
    padding: 30px 28px;
    height: 100%;
  }

  .red-box {
    background-color: red;
    color: ${colours.white};
    padding: 30px 28px;
    height: 100%;
    position: relative;

    h2,
    a {
      color: ${colours.white} !important;
    }
  }
`;

export const contentColouredBoxes = css`
  .grey-box {
    background-color: #eeece9;
    padding: 30px 28px;
    height: 100%;
  }

  .red-box {
    background-color: red;
    color: ${colours.white};
    padding: 30px 28px;
    height: 100%;
    position: relative;

    h2,
    a {
      color: ${colours.white} !important;
    }
  }

  .black-box {
    background-color: ${colours.black};
    color: ${colours.white};
    padding: 30px 28px;
    height: 100%;
    position: relative;

    h2 {
      color: ${colours.white};
    }

    &.arrow-right:before {
      content: '';
      display: block;
      width: 30px;
      height: 45px;
      position: absolute;
      right: 15px;
      top: calc(50% - 17px);
      /* background: url('../images/white-arrow.svg'); */
      /* background-repeat: no-repeat; */
    }
  }

  .color-box {
    width: 100%;
    height: 125px;
    display: block;
    margin-bottom: 10px;

    &.white {
      background-color: ${colours.white};
      border: 1px solid ${colours.black};
    }

    &.black {
      background-color: ${colours.black};
    }

    &.red {
      background-color: ${colours['mitsubishi-red']};
    }

    &.gray-0 {
      background-color: ${colours['mitsubishi-anthracite-gray']};
    }

    &.gray-1 {
      background-color: ${colours['mitsubishi-cool-gray']};
    }

    &.gray-2 {
      background-color: ${colours['mitsubishi-concrete-gray']};
    }
  }
`;

const dotStyles = css`
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin: 0 9px 0 0;
`;

const contentOnlyDotStyles = css`
  content: '';
  position: relative;
  left: 0;
  bottom: -1px;
`;

export const requiredDotStyles = css`
  ${dotStyles};
  border: 2px solid ${OemTheme.colour.requiredDot};
  background: ${OemTheme.colour.requiredDot};
`;

export const recommendedDotStyles = css`
  ${dotStyles};
  border: 2px solid ${OemTheme.colour.default};
  background: white;
`;

const strongTags = css`
  display: block;
  margin-top: ${OemTheme.materialUI.spacing(1)}px;
`;

const navArrowStyles = css`
  .nav-arrows {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .nav-arrow-link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    text-decoration: none;

    span,
    svg {
      font-size: 16px;
      color: #808285;
    }

    svg {
      ${OemTheme.tweaks('navArrow')};
    }

    &:hover {
      text-decoration: none;
    }
  }
`;

export const resourceStyles = css`
  &.content,
  .content {
    ${materialGridStyles};
    ${contentStyles};
    ${oemBaseFontStyles};
    ${contentColouredBoxes};
    ${navArrowStyles};

    ${screenSize(
      { max: '1335px' },
      css`
        .MuiGrid2-container {
          flex-direction: column-reverse;

          .MuiGrid2-grid-xs-12 {
            max-width: 100%;

            .sticky {
              padding-left: 0;
              margin-bottom: 16px;
            }
          }
        }
      `
    )}

    .recommended {
      &:before {
        ${recommendedDotStyles};
        ${contentOnlyDotStyles};
      }
    }

    .required {
      &:before {
        ${requiredDotStyles};
        ${contentOnlyDotStyles};
      }
    }

    .question-listing {
      ${OemTheme.tweaks('resourceQuestionMargin')}

      li {
        margin-bottom: 36px; /* [COE-571] */
      }

      .required,
      .recommended {
        &:before {
          ${OemTheme.tweaks(
            'resourceDotPosition',
            `${css`
              left: -55px;
            `}`
          )}
          margin-right: -16px;
        }
      }
    }

    h1 {
      ${oemGuideH1};
    }

    h2 {
      ${oemGuideH2};
    }

    h3 {
      ${oemGuideH3};
    }

    p {
      ${oemGuideStandardText};
    }

    ol,
    ul {
      ${oemGuideOlUlLists};
    }

    strong {
      ${strongTags};
    }

    .new-flag {
      ${oemGuideNewFlagClass};
    }

    @media (max-width: 898px) {
      margin-top: 70px !important;
    }
  }
`;

export const ResourcesDiv = styled.div`
  ${resourceStyles}
`;
