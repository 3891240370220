import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import { LogoutButton, LanguageToggle } from 'components';
import { Link, useLocation } from 'react-router-dom';
import { ClientRoute } from 'routing/Routes';
import { getTopLevelPath, pathMatch } from 'utility';
import Close from '@mui/icons-material/Close';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { NavBuilder } from './Builder.Nav';
import { useNavStyles, NavDiv } from './styled.Nav';
import { useLanguage } from 'components/util/Language';

import Grid from '@mui/material/Grid2';

export function MobileNav(props: {
  routes: ClientRoute[];
  setMobileOpen: (arg: boolean) => void;
  permittedRoutes: string[];
}) {
  const { setMobileOpen, routes: propRoutes, permittedRoutes } = props;
  const classes = useNavStyles();
  const location = useLocation();
  const topPath = getTopLevelPath(location.pathname);
  const lang = useLanguage();
  const [topLevel, setTopLevel] = useState(topPath === '/');

  const selectedRoute = propRoutes.find((route) =>
    pathMatch(topPath, getTopLevelPath(route.path), true)
  );

  const routes = propRoutes.filter((route) => route.path !== '/');

  useEffect(() => {
    if (topPath === '/' && !topLevel) setTopLevel(true);
    if (topPath !== '/' && topLevel) setTopLevel(false);
  }, [location.pathname, selectedRoute, topPath, topLevel]);

  if (!selectedRoute) return <h1>Invalid path</h1>;

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx({
          [classes.mobiledrawer]: true,
          [classes.drawerOpenMain]: true,
        }),
      }}
      open
    >
      <Close onClick={() => setMobileOpen(false)} className={classes.mobileCloseButton} />
      <NavDiv>
        {routes.map((route, index) => {
          if (
            !permittedRoutes.find((permittedRoute) =>
              pathMatch(permittedRoute, getTopLevelPath(route.path), true)
            )
          ) {
            return null;
          }

          const highlighted = location.pathname.includes(route.path);

          return (
            <>
              {topLevel && (
                <Link
                  key={`${route.title}-${index}`}
                  to={route.noContent ? route.children[0].path : route.path}
                  className={clsx('nav-link', highlighted && 'nav-selected')}
                  onClick={
                    route.children.length === 0 ? () => setMobileOpen(false) : () => null
                  }
                >
                  <p className={clsx('nav-text')}>
                    {lang === 'FR' && route.titleFr ? route.titleFr : route.title}
                  </p>
                  <ArrowRight />
                </Link>
              )}

              {pathMatch(selectedRoute.path, route.path) && (
                <>
                  <Link key={`${index}-${route.path}`} to="/" className={clsx('nav-link')}>
                    <ArrowLeft style={{ fill: 'grey' }} />

                    <p className={clsx('nav-text')}>
                      {lang === 'FR' && route.titleFr
                        ? `Retourner à…  ${route.titleFr}`
                        : `Back to ${route.title}`}
                    </p>
                  </Link>
                  {selectedRoute.children
                    .filter((r) => !r.hideNavLink)
                    .map((con, innerIndex) => (
                      <NavBuilder
                        key={`${innerIndex}-${con.title}`}
                        content={con}
                        nestLevel={1}
                        setMobileOpen={setMobileOpen}
                      />
                    ))}
                </>
              )}
            </>
          );
        })}
        <Grid container mt={15}>
          <Grid>
            <LogoutButton
              buttonStyleOverride={{
                color: (theme) => theme.palette.getContrastText(theme.palette.background.dark),
              }}
            />
          </Grid>
          <Grid>
            <LanguageToggle
              buttonStyleOverride={{
                color: (theme) => theme.palette.getContrastText(theme.palette.background.dark),
              }}
            />
          </Grid>
        </Grid>
      </NavDiv>
    </Drawer>
  );
}
