/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { Typography, Stack} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { PageShell } from 'suites/sterling/molecules/shells/page/PageShell';
import { ContentSection } from 'suites/sterling/molecules/sections/content/ContentSection';
import { Adherence_Cards } from 'generated';
import { useLanguage } from 'suites/sterling/app/context/language';
import { SummaryCard } from './components/SummaryCard';
import { useQuery } from '@apollo/client';
import { MEDIA_TYPES_FEATURED_QUERY } from './queries.AdherenceStandards';
import { ErrorHandle, Loading, Button } from 'components';
import { AdherenceLandingFooter } from './components/AdherenceLandingFooter';

export function AdherenceLanding() {
  const lang = useLanguage();

  const { loading, error, data } = useQuery(MEDIA_TYPES_FEATURED_QUERY, {
    variables: { language: lang },
  });
  if (loading) return <Loading />;
  if (error) return <ErrorHandle gqlError={error} />;
  return (
    <PageShell>
      <ContentSection noTopPadding>
        <ContentSection variant="darkPaper">
          <Typography variant="h3" sx={{ marginBottom: (theme) => theme.spacing(6) }}>
            {{EN: 'The brand adherence dealer program.', FR: 'Programme d’adhésion à la marque pour les concessionnaires.'}[lang]}
          </Typography>
          <Typography variant="h5" sx={{ marginBottom: (theme) => theme.spacing(6), width: '55%', mx: 'auto' }}>
            {{EN: 'Hyundai aims to equip our dealers with every advantage to increase sales performance while consistently promoting the brand.', FR: 'Hyundai aims to equip our dealers with every advantage to increase sales performance while consistently promoting the brand.	Hyundai souhaite fournir à ses concessionnaires tous les moyens d’augmenter leurs performances de vente et d’assurer la cohésion dans la promotion de la marque.'}[lang]}
          </Typography>
          <Typography variant="h5" sx={{ marginBottom: (theme) => theme.spacing(8), width: '55%', mx: 'auto' }}>
            {{EN: 'This program is intended to provide Hyundai Dealers’ direction to ensure that the Hyundai Brand image and customer experience is consistent for Canadian consumers at all tiers of communication.', FR: 'Ce programme vise à fournir aux concessionnaires les conseils dont ils ont besoin pour conserver la cohérence de l’image de marque Hyundai et de l’expérience des clients, et ce, à tous les niveaux de communication avec les consommateurs canadiens.'}[lang]}
          </Typography>
          <Button
            size="large"
            sx={{ mx: 'auto' }}
            href={{ EN: '', FR: '/fr' }[lang] + ('/adherence/about-the-program/')}
          >
            {{ EN: 'Read about the program', FR:  'Lire sur le programme' }[lang]}
          </Button>
        </ContentSection>
        <ContentSection noBottomPadding>
          <Typography variant="h3" sx={{ marginBottom: (theme) => theme.spacing(4) }}>
            {{EN:'Update your advertising media.', FR: 'Mettez à jour vos supports publicitaires.' }[lang]}
          </Typography>
        </ContentSection>
        <Stack
          gap={6}
          direction="row"
          component={Grid}
          container
          justifyContent="center"
          alignItems={{ xs: 'center', lg: 'stretch' }}
          sx={{
            display: 'flex',
            width: '100%',
            paddingBottom: (theme) => theme.spacing(12),
            '& > *': { display:'flex', width: { xs: '70%', lg: '40%' } },
          }}
        >
          {data.mediaFeatured?.map((card: Adherence_Cards) => (
            <SummaryCard
              key={card.key}
              header={card.title}
              subheader={card.link_label}
              image={card.image}
              link_url={card.link_url}
              summary={card.description}
            />
          ))}
        </Stack>
        <Button
            size="large"
            sx={{ mx: 'auto' }}
            href={{ EN: '', FR: '/fr' }[lang] + ('/adherence/media-types/')}
          >
            {{ EN: 'View all media channels', FR:  'Voir tous les canaux de médias' }[lang]}
          </Button>
        <ContentSection noBottomPadding sx={{ mx: 'auto', width: { lg: '65%', xs: '100%' } }}>
          <Typography variant="h3" sx={{ marginBottom: (theme) => theme.spacing(4) }}>
            {{EN:'Working with Hyundai components.', FR: 'Travailler avec des composants Hyundai.'}[lang]}
          </Typography>
          <Typography variant="h5" sx={{ marginBottom: (theme) => theme.spacing(8) }}>
            {{EN:'Ensure you are using Hyundai components like logos and eye-catchers properly.', FR: 'Assurez-vous que vous utilisez correctement les composants de Hyundai, comme les logos et les accroches.'}[lang]}
          </Typography>
          <Stack
            gap={6}
            direction="row"
            component={Grid}
            container
            justifyContent="center"
            alignItems={{ xs: 'center', md: 'stretch' }}
            sx={{
              width: '100%',
              paddingBottom: (theme) => theme.spacing(12),
              '& > div': { display:'flex', width: { xs: '70%', lg: '30%' } },
            }}
          >
            {data.componentsFeatured?.map((card: Adherence_Cards) => (
              <SummaryCard
                small
                key={card.key}
                header={card.title}
                subheader={card.link_label}
                image={card.image}
                link_url={card.link_url}
                summary={card.description}
              />
            ))}
          </Stack>
          <Button
            size="large"
            sx={{ mx: 'auto' }}
            href={{ EN: '', FR: '/fr' }[lang] + ('/adherence/brand-components/')}
          >
            {{ EN: 'View all brand components', FR: 'Voir les composants de la marque' }[lang]}
          </Button>
        </ContentSection>
      </ContentSection>
      <ContentSection>
        <Typography variant="h3" sx={{ marginBottom: (theme) => theme.spacing(4) }}>
          {{EN: 'Get help.', FR: `Demandez de l'aide.`}[lang]}
        </Typography>
        <Typography variant="h5" sx={{ marginBottom: (theme) => theme.spacing(8) }}>
          {{EN: `Have questions about the program or your advertising media?`, FR: `Vous avez des questions sur le programme ou sur vos supports publicitaires?`}[lang]}
        </Typography>
        <Button
            size="large"
            sx={{ mx: 'auto' }}
            href={{ EN: '', FR: '/fr' }[lang] + ('/adherence/support/')}
          >
            {{ EN: 'Visit our support page', FR:  `Visitez notre page d'assistance` }[lang]}
          </Button>
      </ContentSection>
      <ContentSection noTopPadding noBottomPadding>
        <AdherenceLandingFooter/>
      </ContentSection>
    </PageShell>
  );
}
