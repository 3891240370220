import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Adherence_Criteria } from 'generated';
import { useLocalStorage } from 'components/util/LocalStorage';

export const useAdherenceReviewModel = () => {
  const { requestId } = useParams<{ requestId: string }>();
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [standardsDisplayToggle, setStandardsDisplayToggle] = useState<boolean>(true);
  const ADHERENCE_BY_ID_QUERY = gql`
    query getAdherenceReviewById($requestId: String!) {
      adherenceSubmissionOne(filter: { requestId: $requestId }) {
        _id
        requestId
        dealershipName
        createdAt
        status
        invoiceNumber
        mediaChannel
        audience
        carModel
        campaign
        activity
        attachments {
          filename
          preview
        }
        period
        criteria {
          _id
          category
          category_order
          question
          question_key
          question_order
          correct_response
          question_dependencies
          correct_response
          criteria_image
          media_channels
          periods
        }
      }
    }
  `;
  const { loading, error, data } = useQuery(ADHERENCE_BY_ID_QUERY, {
    skip: !requestId,
    variables: { requestId },
  });
  const redirectToSubmissionPage = () => {
    // Small buffer to account for writes to localstorage acting as a race condition
    setTimeout(() => navigate(`/adherence/review/submit/${requestId}`), 500);
  };

  return {
    currentQuestionIndex,
    setCurrentQuestionIndex,
    standardsDisplayToggle,
    setStandardsDisplayToggle,
    redirectToSubmissionPage,
    loading,
    error,
    data,
  };
};

export const useCriteriaListState = (reviewId: string) => {
  const [reviewState, setReviewState] = useState<{ [key: string]: boolean }>();
  const [reviewFeedback, setReviewFeedback] = useState<string>('');
  const criteriaQuestionSort = (a: Adherence_Criteria, b: Adherence_Criteria) =>
    b.question_order > a.question_order ? -1 : b.question_order < a.question_order ? 1 : 0;

  // This one is used. Sorts by category and then question (only if categories are equal)
  const criteriaCategorySort = (a: Adherence_Criteria, b: Adherence_Criteria) =>
    b.category_order > a.category_order
      ? -1
      : b.category_order < a.category_order
      ? 1
      : criteriaQuestionSort(a, b);

  const [locallyStoredReview, setLocallyStoredReview] = useLocalStorage(`review-${reviewId}`, []);

  const updateFeedbackMsg = (feedback: string) => {
    setReviewFeedback(feedback);
    setLocallyStoredReview({ ...locallyStoredReview, feedback });
  };

  const handleReviewStateUpdate = (newReviewState: { [key: string]: boolean } | undefined) => {
    setLocallyStoredReview({
      requestId: reviewId,
      feedback: reviewFeedback,
      answerState: newReviewState,
    });
  };

  const deleteAnswerFromAnswerState = (questionKey: string) => {
    const { answerState } = locallyStoredReview;
    if (!answerState) return;
    const revisedAnswerState = { ...answerState };
    delete revisedAnswerState[questionKey];
    setLocallyStoredReview({ ...locallyStoredReview, answerState: revisedAnswerState });
  };

  const setReviewDetails = (details: {
    mediaFilename?: string;
    feedback?: string;
    mediaChannel?: string;
  }) => {
    setLocallyStoredReview({ ...locallyStoredReview, ...details });
  };

  return {
    reviewState,
    setReviewState,
    criteriaCategorySort,
    locallyStoredReview,
    updateFeedbackMsg,
    deleteAnswerFromAnswerState,
    setReviewDetails,
    handleReviewStateUpdate,
  };
};
