import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { AppState } from 'sterling-redux/store';
import {
  ActiveSelectionsAction,
  ActiveSelectionsState,
} from 'sterling-redux/reducers/activeSelections';
import { IParams, getUrlLanguage } from 'utility';

export interface DeepLinkParam {
  name: keyof ActiveSelectionsState;
  reducer: (x: string) => ActiveSelectionsAction;
}

interface DeepLinkProps {
  boundParams: DeepLinkParam[];
  baseUrlEnding: string;
}
//!!!!!!! READ THIS !!!!!!!
/**
 * If you're adding deep linking to a route, don't forget
 * to add REST params to the route in src/routing/Routes.tsx
 */
export const useDeepLink = (props: DeepLinkProps) => {
  const { boundParams, baseUrlEnding } = props;
  const [deepLinkProcessed, setDeepLinkProcessed] = useState(false);
  const navigate = useNavigate();
  const queryParams = useParams<IParams>();
  const dispatch = useDispatch();
  const location = useLocation();
  const updateDeepLinkProcessed = () => {
    setDeepLinkProcessed(!deepLinkProcessed);
  };
  const boundParamState = useSelector((state: AppState) => {
    const newState: Partial<Record<keyof ActiveSelectionsState, string>> = {};
    boundParams.forEach((param: DeepLinkParam) => {
      newState[param.name] = state.activeSelections[param.name] as string;
    });
    return newState;
  }, shallowEqual);

  const basePath = location.pathname.split(baseUrlEnding).slice(0, -1).join('/');
  const fallbackUrl = `${basePath}${baseUrlEnding}`;

  const generateUrlFromBoundParams = () =>
    boundParams.reduce(
      (url: string, param: DeepLinkParam) => url + `/${boundParamState[param.name]}`,
      ''
    );

  const clearParamStoreState = () => {
    boundParams.forEach((param: DeepLinkParam) => {
      dispatch(param.reducer(''));
    });
  };

  function DeepLinkFallback(fallbackProps: { errorOverride?: { en: string; fr: string } }) {
    const { errorOverride } = fallbackProps;
    if (Object.keys(queryParams).length) {
      clearParamStoreState();
      navigate(fallbackUrl, { replace: true, state: { preventNavigation: true } });
    }
    return (
      <h1
        style={{
          textAlign: 'center',
          marginTop: '36px',
        }}
      >
        {
          {
            EN: errorOverride?.en || 'No dealers registered to this account.',
            FR: errorOverride?.fr || `Aucun concessionnaire n'est enregistré sur ce compte.`,
          }[getUrlLanguage(location.pathname)]
        }
      </h1>
    );
  }

  useEffect(() => {
    if (Object.keys(queryParams).length && !deepLinkProcessed) {
      updateDeepLinkProcessed();
      const shouldUpdate = boundParams.some(
        (param) =>
          queryParams[param.name] && queryParams[param.name] !== boundParamState[param.name]
      );

      if (shouldUpdate) {
        boundParams.forEach((param: DeepLinkParam) => {
          dispatch(param.reducer(queryParams[param.name] || ''));
        });
      }
    }
  }, [queryParams]);

  useEffect(() => {
    const currentUrl = location.pathname;
    const updatedUrl = `${basePath}${baseUrlEnding}${generateUrlFromBoundParams()}`;

    if (currentUrl !== updatedUrl) {
      navigate(updatedUrl, { replace: true, state: { preventNavigation: true } });
    }
  }, [boundParamState]);

  return Object.assign(boundParamState, {
    DeepLinkFallback: (fallbackProps: { errorOverride?: { en: string; fr: string } }) => (
      <DeepLinkFallback errorOverride={fallbackProps.errorOverride} />
    ),
  });
};
export default useDeepLink;
