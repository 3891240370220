/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState, useRef } from 'react';
import { Box, Button} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { KbdShortcut } from 'components/general/KbdShortcut';
import { Loading } from 'components';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Adherence_Submissions } from 'generated';

export function LoadingOrChild(props: {
  children: React.ReactNode;
  dependency: boolean;
  loadTrigger?: () => void;
}) {
  if (!props.dependency) props.loadTrigger?.();
  return <>{props.dependency ? props.children : <Loading />} </>;
}

export function AdherenceContentViewer(props: {
  media: Adherence_Submissions['attachments'][number];
}) {
  const { media } = props;
  const [currentMediaId, setCurrentMediaId] = useState<string>('');
  const [mediaUrl, setMediaUrl] = useState<string>('');
  const { requestId } = useParams<{ requestId: string }>();
  const audioRef = useRef<HTMLAudioElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const testMediaType = (type: string | RegExp) => RegExp(type).test(media.type);

  const ADHERENCE_SUBMISSION_MEDIA_QUERY = gql`
    query getAdherenceSubmissionMediaById($requestId: String!) {
      adherenceSubmissionOne(filter: { requestId: $requestId }) {
        _id
        requestId
        attachments {
          id
          url(requestId: $requestId)
        }
      }
    }
  `;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMediaUrlUpdate = (data: any) => {
    const newMediaUrl =
      data?.adherenceSubmissionOne?.attachments.find(
        (m: typeof media) => m.id === media.id
      ).url;

    if (newMediaUrl) setMediaUrl(`${newMediaUrl}`);
  };

  const adherenceSubmissionMediaQuery = useQuery(ADHERENCE_SUBMISSION_MEDIA_QUERY, {
    variables: { requestId },
    skip: !requestId,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    refetchWritePolicy: 'overwrite',
    pollInterval: 9000,
    onCompleted: handleMediaUrlUpdate,
  });


  useEffect(() => {
    adherenceSubmissionMediaQuery.stopPolling();
    adherenceSubmissionMediaQuery.startPolling(9000);
  }, [adherenceSubmissionMediaQuery.data]);

  // clear cached file on media change for video and audio
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
    }

    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.load();
    }
  }, [mediaUrl]);

  // Watches for current media switch and removes the mediaUrl
  useEffect(() => {
    if (media.id !== currentMediaId) {
      setMediaUrl('');
      setCurrentMediaId(media.id);
      adherenceSubmissionMediaQuery.refetch();
    }
  });

  if (adherenceSubmissionMediaQuery.loading || !adherenceSubmissionMediaQuery.data)
    return <Loading />;

  return testMediaType('application/pdf') ? (
    <>
      <img
        src={`${media.preview}`}
        width="100%"
        style={{ cursor: mediaUrl ? 'pointer' : 'progress' }}
        alt={media.filename}
        onClick={
          mediaUrl === '' || mediaUrl === undefined
            ? () => null
            : () => {
                window.open(mediaUrl, '_blank');
                return false;
              }
        }
      />
      {/* <embed src={mediaUrl} height="850" width="100%" /> */}
    </>
  ) : testMediaType(/image/g) ? (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    (mediaUrl === '' || mediaUrl === undefined ? (<img src={`${media.preview}`} width="100%" alt={encodeURI(media.filename)} />) : (<a href={mediaUrl}>
      <img src={`${media.preview}`} width="100%" alt={encodeURI(media.filename)} />
    </a>))
  ) : testMediaType(/video/g) ? (
    <KbdShortcut
      char="space"
      onTap={() =>
        videoRef.current?.paused ? videoRef.current?.play() : videoRef.current?.pause()
      }
    >
      <LoadingOrChild dependency={!!mediaUrl}>
        <video
          controls
          autoPlay
          muted
          width="100%"
          onLoadedData={adherenceSubmissionMediaQuery.stopPolling}
        >
          <source src={mediaUrl} type={media.type} />
        </video>
      </LoadingOrChild>
    </KbdShortcut>
  ) : testMediaType(/audio/g) ? (
    <KbdShortcut
      char="space"
      onTap={() =>
        audioRef.current?.paused ? audioRef.current?.play() : audioRef.current?.pause()
      }
    >
      {/* loadtrigger is a bit of a hack/bandaid. should find better solution */}
      <LoadingOrChild
        dependency={!!mediaUrl}
        loadTrigger={() => {
          if (!adherenceSubmissionMediaQuery.loading)
            adherenceSubmissionMediaQuery.startPolling(15000);
        }}
      >
        <audio
          controls
          ref={audioRef}
          onLoadedData={() => {
            adherenceSubmissionMediaQuery.stopPolling();
          }}
        >
          <source src={mediaUrl} type={media.type} />
        </audio>
      </LoadingOrChild>
    </KbdShortcut>
  ) : (
    <Box
      component={Grid}
      container
      justifyContent="center"
      alignItems="center"
      sx={{ width: '100%', height: 400 }}
    >
      <Box component={Grid} >
        <LoadingOrChild dependency={!!mediaUrl}>
          <Button href={mediaUrl}>Download {media.filename}</Button>
        </LoadingOrChild>
      </Box>
    </Box>
  );
}
