import { useNavigate, useParams } from "react-router-dom";
import { COMPLIANCE_ROUTE } from "../business-logic";

/**
 * Designed to automatically handle the state of the `/fr` language path and the `/COMPLIANCE_ROUTE` suite portion of the URL.
 * Simply push the path you wish to append including the leading slash. For example `/support` and it will automatically
 * be transformed into `/fr/COMPLIANCE_ROUTE/support` if the user has the language set to French.
 */
export const useComplianceHistory = () => {
  const navigate = useNavigate();
  const params = useParams<{fr?: 'fr'}>();

  const push = (routeWithSlash: string) =>
    navigate(`${params.fr ? "/fr" : ""}/${COMPLIANCE_ROUTE}${routeWithSlash}`);

  return { push };
};
