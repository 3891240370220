import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Drawer } from '@mui/material';
import { useNavStyles, NavDiv } from 'views/layout/navigation/styled.Nav';
import { Logo } from 'components';
import { useLocation, Navigate } from 'react-router-dom';
import { getTopLevelPath, pathMatch } from 'utility';
import { ClientRoute } from 'routing/Routes';
import { NavBuilder } from './Builder.Nav';

export function SubNav(props: { routes: ClientRoute[]; permittedRoutes: string[] }) {
  const { routes, permittedRoutes } = props;
  const classes = useNavStyles();
  const location = useLocation();

  const topPath = getTopLevelPath(location.pathname);
  const selectedRoute = routes.find((route) =>
    pathMatch(topPath, getTopLevelPath(route.path), true)
  );

  const [open, setOpen] = useState(selectedRoute && selectedRoute.children.length > 0);

  useEffect(() => {
    if (selectedRoute && selectedRoute.children.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [location.pathname, selectedRoute]);

  if (!selectedRoute) return <Navigate to="/" />;

  return (
    <Drawer
        variant="permanent"
        classes={{
          paper: clsx({
            [classes.drawerOpenSub]: open,
            [classes.drawerCloseSub]: !open,
            [classes.drawer]: true,
          }),
        }}
        open={open}
      >
        <Logo invis={!open} logoType="sub" />
        <NavDiv className={classes.linkWrapperSub}>
          {selectedRoute.children
            .filter((r) => !r.hideNavLink)
            .map((con, index) => {
              if (con.private) {
                if (!permittedRoutes.some((r) => pathMatch(r, `/${selectedRoute.path}/${con.path}`, true))) {
                  return null;
                }
              }
              return <NavBuilder key={index} parentRoute={selectedRoute} content={con} nestLevel={1} />;
            })}
        </NavDiv>
      </Drawer>
  );
}
