import { makeStyles, createStyles } from '@mui/styles';
import { colours } from 'assets/styles/themes/resources';
import { OemTheme } from 'assets/styles/themes/functions';
import styled, { css } from 'styled-components';
import { IE11Fix, screenSize } from 'assets/styles/themes/mixins';
import { Theme } from '@mui/material/styles';

export const useNavStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      mobileCloseButton: {
        position: 'absolute',
        display: 'inline-block',
        right: '10px',
        top: '10px',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        color: OemTheme.colour.nav.link.default,
      },
      drawer: {
        backgroundColor: OemTheme.colour.nav.background,
        display: 'flex',
        float: 'left',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        left: '0',
        position: 'relative',
        overflowY: 'auto',
        '& a.nav-link': {
          width: '100%',
        },
      },
      mobiledrawer: {
        width: '100vw !important',
        backgroundColor: colours.black,
        height: '100vh !important',
      },
      drawerOpenMain: {
        scrollbarWidth: 'thin',
        width: '100%',
      },
      drawerCloseMain: {
        scrollbarWidth: 'none',
        overflowX: 'hidden',
        width: '25%',
        [theme.breakpoints.up('sm')]: {
          width: 86,
        },
        backgroundColor: `${OemTheme.colour.nav.background} !important`,
        // Weird number is so the menu items line up when there is a sub drawer
        paddingTop: `${250-((Number(theme.custom.navLogo.paddingTop) || 105)+72)}px`
      },
      drawerOpenSub: {
        scrollbarWidth: 'thin',
        height: '100vh',
        backgroundColor: `${colours.white} !important`,
        width: 'calc(100% - 86px)',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerCloseSub: {
        display: 'none',
        width: '100%',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflow: 'hidden',
      },
      linkWrapper: {
        width: '100%',
        paddingTop: `${(Number(theme.custom.navLogo.paddingTop) || 105)+72}px`,
      },
      linkWrapperSub: {
        width: '100%',
        paddingTop: '250px',
        overflowY: 'visible',
      },
    })
  )();

export const NavDiv = styled.div`
  ::-webkit-scrollbar {
    z-index: 20;
  }

  .nav-link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    text-decoration: none;
    width: 100%;
    flex-grow: 1;
    min-height: 60px;

    ${IE11Fix(
      css`
        height: 60px;
      `
    )}

    background-color: ${OemTheme.colour.nav.background};
    color: ${OemTheme.colour.nav.link.default};

    ${screenSize(
      { min: '900px' },
      css`
        svg path {
          fill: ${OemTheme.colour.nav.link.default};
        }
      `
    )}

    padding-left: 12px;

    span {
      font-family: ${OemTheme.font.subNav};
    }

    &.nav-selected,
    &.nested-selected {
      background-color: ${OemTheme.colour.nav.link.background.selected};
      color: ${OemTheme.colour.nav.link.selected};

      svg path {
        fill: ${OemTheme.colour.nav.link.selected};
      }
    }

    &.nav-link-sub {
      min-height: 45px;
      background-color: white;
      color: black;
      padding: 6px 12px;

      span {
        font-size: 14px;
        ${OemTheme.tweaks('textVertAlign')}
      }

      &.nested-selected {
        font-family: ${OemTheme.font.medium};
        background-color: ${OemTheme.colour.nav.link.background.selected};
        color: ${OemTheme.colour.nav.link.selected};

        span {
          font-family: ${OemTheme.font.medium};
        }
      }
    }

    &.nested2 {
      min-height: 30px;
      padding-left: 24px;

      &.nested-selected {
        background-color: white;
        color: ${OemTheme.colour.nav.link.selectedOnWhite};

        span {
          font-family: ${OemTheme.font.bold};
        }
      }
    }

    &.nested3 {
      min-height: 30px;
      padding-left: 36px;
      color: ${OemTheme.colour.nav.link.tertiaryText};

      &.nested-selected {
        background-color: white;

        span {
          font-family: ${OemTheme.font.subNav};
          color: black;
          /* 4D is 30% opacity */
          background-color: ${OemTheme.colour.background.primary + '4D'};
          border: 1px solid black;
          margin: -1px -4px;
          padding: 1px 4px;
        }
      }
    }

    &.nested4 {
      min-height: 30px;
      padding-left: 48px;

      &.nested-selected {
        background-color: white;
        color: black;

        span {
          font-family: ${OemTheme.font.bold};
        }
      }
    }

    &.nested5 {
      min-height: 30px;
      padding-left: 60px;

      &.nested-selected {
        background-color: white;
        color: black;

        span {
          font-family: ${OemTheme.font.regular};
          font-style: italic;
        }
      }
    }

    &:hover {
      background-color: ${OemTheme.colour.nav.link.background.hover} !important;
      color: ${OemTheme.colour.nav.link.hover} !important;

      svg path {
        fill: ${OemTheme.colour.nav.link.hover} !important;
      }

      &:not(.nav-link-sub) {
        color: ${OemTheme.tweaks('mainNavHoverText')} !important;
        background-color: ${OemTheme.tweaks('mainNavHoverBackground')} !important;

        svg path {
          fill: ${OemTheme.tweaks('mainNavHoverText')} !important;
        }
      }
    }
  }

  .nav-icon {
    &:before {
      display: block;
    }
    position: relative;
    width: 50px;
    margin-right: 16px;
    text-align: center;
    font-size: 30px;
  }

  .nav-text {
    font-size: 20px;
    ${screenSize(
      { max: '1900px' },
      css`
        font-size: 18px;
      `
    )}
    ${OemTheme.tweaks('textVertAlign')}

    &.nav-open {
      display: none;
    }
  }

  .nav-shrink {
    min-height: 45px;
    justify-content: center;

    .nav-icon {
      font-size: 25px;
      ${IE11Fix(
        css`
          font-size: 2rem;
          margin-right: 8px;
          margin-left: 8px;
        `
      )}
    }

    .nav-text {
      display: none;
    }

    [class^='Icon__SvgContainer'] > svg {
      height: 26px !important;
    }
  }
`;
