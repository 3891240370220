import Stack from '@mui/material/Stack';
import { CaseCard } from 'suites/compliance/components/cards/case/CaseCard';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import {
  ComplianceEvidenceGetResponse,
  StrictComplianceCase,
} from 'suites/compliance/app/business-logic';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';
import { toTitleCase } from 'utility';
import { useUnitsManyQuery } from 'generated';
import { useEffect, useState } from 'react';
import { Galleryer } from 'suites/sterling/molecules/widgets/dropzone/Galleryer';

export function CaseInformationCard({
  fullCase,
  currentRevision,
}: {
  fullCase: StrictComplianceCase;
  currentRevision: StrictComplianceCase['caseInformation'][number];
}) {
  const t = useTranslation();
  const unitsQuery = useUnitsManyQuery();
  const reportedDealerName = unitsQuery.data?.unitsMany.find(
    (d) => d.code === fullCase.reported.dealerCode
  )?.name;

  const { caseId } = fullCase;
  const { revision } = currentRevision;

  const [evidence, setEvidence] = useState<ComplianceEvidenceGetResponse[]>([]);

  useEffect(() => {
    const uri =
      process.env.NODE_ENV === 'development'
        ? `http://hac.localhost.io:4000/rest/compliance/get/${caseId}/${revision}`
        : `/services/rest/compliance/get/${caseId}/${revision}`;

    fetch(uri, {
      credentials: 'include',
    })
      .then((res) => {
        return res.json();
      })
      .then((resBody: ComplianceEvidenceGetResponse[]) => {
        setEvidence(resBody);
      });
  }, []);

  return (
    <div>
      <CaseCard
        title={t(c.pages.reviewCases.caseDetail.caseInformation)}
        sx={{ borderBottom: 'none' }}
      >
        <Typography variant="subtitle2">{t(c.formTerms.caseSummary)}</Typography>
        <Grid container direction="row" wrap="nowrap">
          <Grid
            container
            sx={{
              p: 3,
              marginRight: 5,
              backgroundColor: 'background.paper',
              opacity: 0.6,
            }}
            size={8}
          >
            <Typography variant="body2">{currentRevision.summary}</Typography>
          </Grid>
          <Stack direction="column" spacing={2} component={Grid} size={{ xs: 4 }}>
            <Typography variant="body2">
              <span style={{ fontWeight: 'bold' }}>{t(c.formTerms.reporterName)}: </span>
              {reportedDealerName ?? '-'}
            </Typography>
            <Typography variant="body2">
              <span style={{ fontWeight: 'bold' }}>{t(c.formTerms.reportedDealerCode)}: </span>
              {toTitleCase(fullCase.reported.dealerCode)}
            </Typography>
            <Typography variant="body2">
              <span style={{ fontWeight: 'bold' }}>{t(c.formTerms.caseCategory)}:</span>{' '}
              {currentRevision.categoryOther || toTitleCase(currentRevision.category)}
            </Typography>
            <Typography variant="body2">
              <span style={{ fontWeight: 'bold' }}>{t(c.terms.outcome)}:</span>{' '}
              {toTitleCase(fullCase.outcome)}
            </Typography>
            <Typography variant="body2">
              <span style={{ fontWeight: 'bold' }}>{t(c.formTerms.modifiedDate)}:</span>{' '}
              {new Date(currentRevision.modifiedDate).toDateString()}
            </Typography>
            <Typography variant="body2">
              <span style={{ fontWeight: 'bold' }}>{t(c.formTerms.caseOngoing)}:</span>{' '}
              {currentRevision.ongoing}
            </Typography>
            <Typography variant="body2">
              <span style={{ fontWeight: 'bold' }}>{t(c.formTerms.caseDate)}:</span>{' '}
              {new Date(currentRevision.infractionDate).toDateString()}
            </Typography>
          </Stack>
        </Grid>

        {currentRevision.infractionLocation && (
          <Grid container direction="row" wrap="nowrap">
            <Stack
              direction="column"
              marginTop={6}
              spacing={2}
              component={Grid}
              size={{ xs: 8 }}
            >
              <Typography variant="body2">
                <span style={{ fontWeight: 'bold' }}>Location:</span>{' '}
                {currentRevision.infractionLocation}
              </Typography>
            </Stack>
          </Grid>
        )}
      </CaseCard>
      <CaseCard
        title={t(c.pages.reviewCases.caseDetail.submittedMedia)}
        sx={{ borderTop: 'none' }}
      >
        <Galleryer filesOrLinks={{ data: evidence }} justifyContent="flex-start" />
      </CaseCard>
    </div>
  );
}
