import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Content_Notifications } from 'generated';
import { useNavigate } from 'react-router-dom';
import { handleEnterKey } from 'utility';
import { OemTheme } from 'assets/styles/themes/functions';
import { useLanguage } from 'components/util/Language';

const PANE_WIDTH = 289;
const PANE_LEFT_RIGHT_PADDING = 4;
const PANE_TOP_BOTTOM_PADDING = 12;
const TRIANGLE_SIZE = 33;
const TOP_OFFSET = 90;

const Pane = styled.div`
  position: absolute;
  top: ${TOP_OFFSET}px;
  width: ${PANE_WIDTH}px;
  margin: 0 !important;
  padding: 0 !important;
  background-color: ${OemTheme.figma.colour.base.light};
  border: 1px solid black;
  max-height: calc(100vh - ${TOP_OFFSET + 20}px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .arrow_box {
    background-color: ${OemTheme.figma.colour.base.dark} !important;
    color: ${OemTheme.figma.colour.contrast.dark} !important;
    padding: ${PANE_LEFT_RIGHT_PADDING}px ${PANE_TOP_BOTTOM_PADDING}px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    &:hover {
      cursor: initial;
    }
  }

  .arrow_box:after,
  .arrow_box:before {
    bottom: 100%;
    right: ${TRIANGLE_SIZE / 4}px;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .arrow_box:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: ${OemTheme.figma.colour.base.dark};
    border-width: ${TRIANGLE_SIZE / 2}px;
    margin-left: ${-TRIANGLE_SIZE / 2}px;
  }

  div {
    margin: 0 !important;
    padding: ${PANE_TOP_BOTTOM_PADDING}px;
    border-bottom: 1px solid black;

    &:hover {
      background-color: ${OemTheme.figma.colour.base.hoverOnLight};
      a {
        color: ${OemTheme.figma.colour.contrast.hoverOnLight} !important;
      }
    }
  }

  div:last-child {
    border-bottom: none;
  }

  .title {
    font-size: 12px !important;
  }

  .close-button {
    transform: translateX(${-TRIANGLE_SIZE / 4}px);
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    transform: translateX(-${PANE_WIDTH}px); /* IE11 */
  }
`;

const Text = styled.a`
  font-size: 18px !important;
  color: ${OemTheme.figma.colour.contrast.light};
  margin-top: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &.without-underline {
    text-decoration: none !important;
  }
`;

export function NotificationPane(props: {
  notifications: Partial<Content_Notifications>[];
  handleClick: (id: string) => void;
  closePane: () => void;
}) {
  const { notifications, handleClick, closePane } = props;
  const navigate = useNavigate();
  const lang = useLanguage();
  const paneRef = useRef<HTMLDivElement | null>(null);

  const viewPreviousClick = () => {
    const prefix = lang === 'EN' ? '' : '/fr';
    navigate(`${prefix}/notifications`);
    closePane();
  };

  const handleClickAnywhere = useCallback(
    (e: MouseEvent) => {
      const paneNode = paneRef.current;
      const targetNode = e.target as HTMLElement;
      if (paneNode && targetNode) {
        if (!paneNode.contains(targetNode)) {
          closePane();
        }
      }
    },
    [closePane]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickAnywhere);
    return () => {
      document.removeEventListener('mousedown', handleClickAnywhere);
    };
  }, [handleClickAnywhere]);

  return (
    <Pane ref={paneRef}>
      <>
        <div className="arrow_box">
          <small className="title" style={{cursor: 'default'}}>{{ EN: 'Notifications', FR: 'Notifications' }[lang]}</small>

          <small
            className="title close-button"
            onClick={closePane}
            onKeyPress={(e) => handleEnterKey(e, closePane)}
            aria-label="Close pane"
            role="button"
            tabIndex={0}
          >
            X
          </small>
        </div>
        {notifications.map((n, index) => {
          const notificationClick = () => {
            handleClick(`${n.key}`);
            closePane();
          };
          return (
            <div
              key={n._id}
              title={n.button_link}
              onClick={(e)=> {e.preventDefault(); notificationClick()}}
              onKeyPress={(e) => {e.preventDefault(); handleEnterKey(e, notificationClick)}}
              aria-label="Link to more details"
              className='in-pane-notification'
              role="button"
              tabIndex={index}
            >
              <Text className="without-underline">{n.description}</Text>
            </div>
          );
        })}
        <div
          onClick={viewPreviousClick}
          onKeyPress={(e) => handleEnterKey(e, viewPreviousClick)}
          aria-label="Link to more details"
          role="button"
          tabIndex={notifications.length}
        >
          <Text>
            {lang === 'EN'
              ? 'View previous notifications'
              : 'Afficher les notifications précédentes'}
          </Text>
        </div>
      </>
    </Pane>
  );
}
