import { gql, useLazyQuery } from '@apollo/client';
import { useAuthorization } from 'suites/sterling/app/context/authorization';
import { useEffect, useState } from 'react';
import { useLanguageController } from 'components';

export const LOGIN_QUERY = gql`
  query login($username: String!, $password: String!, $isPresentation: Boolean) {
    validateLogin: login(
      username: $username
      password: $password
      isPresentation: $isPresentation
    ) {
      error
      language
    }
  }
`;

export const useLogin = () => {
  const { refreshAuth } = useAuthorization();
  const { setURLLanguage } = useLanguageController();
  const [login, result] = useLazyQuery(LOGIN_QUERY, {
    fetchPolicy: 'network-only',
  });
  const [error, setError] = useState<string>();

  useEffect(() => {
    const errorMessage = result.data?.validateLogin?.error;
    setError(errorMessage);
    refreshAuth();
    const loginLanguage  = result.data?.validateLogin?.language ?? 'EN';
    setURLLanguage(loginLanguage);
    if (result.error) {
      setError('Unable to login. Try again in 30 seconds.');
    }
  }, [refreshAuth, result.data]);

  return { login, error };
};
