import { useEffect } from 'react';

export function DownloadRoute({ fileUrl }: { fileUrl: string }) {
  useEffect(() => {
    // Trigger the file download by redirecting the browser.
    window.location.href = fileUrl;
  }, [fileUrl]);

  return null; // Nothing to render since we're redirecting
}
