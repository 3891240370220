import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CheckIcon from '@mui/icons-material/Check';
import XIcon from '@mui/icons-material/Clear';
import { KbdShortcut } from 'components/general/KbdShortcut';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentSection } from 'suites/sterling/molecules/sections/content/ContentSection';
import { PageShell } from 'suites/sterling/molecules/shells/page/PageShell';
import { useLocalStorage } from 'components/util/LocalStorage';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Loading } from 'components';

export function AdherenceReviewSubmit() {
  const navigate = useNavigate();
  const { requestId } = useParams<{ requestId: string }>();
  const [reviewWithIdExists, setReviewWithIdExists] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [reviewState, _setReviewState, clearReviewState] = useLocalStorage(
    `review-${requestId}`
  );

  // Used to format out "optional/folderQuestions beofre they are submitted"
  const [reviewSubmission, setReviewSubmission] = useState<typeof reviewState>();
  const redirectBackToReview = () =>
    setTimeout(() => navigate(`/adherence/review/id/${requestId}`), 500);
  const handleReviewSubmissionUpdate = (optionalQuestionKeys: [string]) => {
    const { answerState, ...rest } = reviewState;

    optionalQuestionKeys.forEach((questionKey) => {
      delete answerState[questionKey];
    });
    setReviewSubmission({ ...rest, answerState });
  };

  const clearStorageAndRedirect = () => {
    clearReviewState();
    navigate('/adherence/review/list');
  };

  const emptyFeedbackQuesitonsQuery = useQuery(
    gql`
      query getQuestionsWithEmptyFeedback {
        adherenceCriteriaMany(filter: { fail_response: "" }) {
          question_key
        }
      }
    `,
    {
      onCompleted: (data) => {
        handleReviewSubmissionUpdate(
          data.adherenceCriteriaMany.map(
            (question: { question_key: string }) => question.question_key
          )
        );
      },
    }
  );

  const checkReviewExistenceQuery = useQuery(
    gql`
      query reviewExistenceQuery($requestId: String!) {
        adherenceReviewFindOne(filter: { requestId: $requestId }) {
          requestId
          mediaFilename
          feedback
          answerState
        }
      }
    `,
    { variables: { requestId }, fetchPolicy: 'no-cache' }
  );
  const reviewCriteriaQuery = useQuery(
    gql`
      query reviewCriteriaQuery($requestId: String!) {
        adherenceSubmissionOne(filter: { requestId: $requestId }) {
          mediaChannel
          criteria {
            question
            question_key
          }
        }
      }
    `,
    { variables: { requestId }, fetchPolicy: 'no-cache' }
  );

  const [createReview] = useMutation(gql`
    mutation createAdherenceReview(
      $review: CreateOneAdherence_ReviewsInput!
      $requestId: String!
    ) {
      adherenceReviewCreateOne(record: $review) {
        recordId
      }
      adherenceSubmissionMarkComplete(requestId: $requestId)
    }
  `);

  const [updateReview] = useMutation(gql`
    mutation updateAdherenceReview(
      $review: UpdateOneAdherence_ReviewsInput!
      $requestId: String!
    ) {
      adherenceReviewUpdateOne(record: $review, filter: { requestId: $requestId }) {
        recordId
      }
    }
  `);

  const [removeReview] = useMutation(gql`
    mutation removeAdherenceReview($requestId: String!) {
      adherenceReviewRemoveOne(filter: { requestId: $requestId }) {
        recordId
      }
    }
  `);

  const [sendTMBCreativeJudgement] = useMutation(gql`
    mutation sendJudgementToTMB(
      $requestId: String!
      $approved: Boolean!
      $rejected: Boolean!
    ) {
      adherenceReviewSendApproval(requestId: $requestId) @include(if: $approved)
      adherenceReviewSendDenial(requestId: $requestId) @include(if: $rejected)
    }
  `);

  const isApproved = reviewSubmission?.answerState
    ? Object.values(reviewSubmission?.answerState).every((a) => a === true)
    : false;

  const handleReviewExistence = () => {
    setReviewWithIdExists(true);
  };

  const deleteExistingReview = () => {
    if (requestId) {
      removeReview({ variables: { requestId } });
      clearReviewState();
      redirectBackToReview();
    }
  };

  const sendToTMBOptions = {
    variables: {
      requestId: reviewSubmission?.requestId,
      approved: isApproved,
      rejected: !isApproved,
    },
    onCompleted: () => {
      clearStorageAndRedirect();
    },
  };
  const adherenceReviewCreateOrUpdateOptions = {
    variables: {
      review: reviewSubmission,
      requestId: reviewSubmission?.requestId,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (err: any) => {
      // eslint-disable-next-line no-console
      console.log(err);
    },
    onCompleted: () => sendTMBCreativeJudgement(sendToTMBOptions),
  };

  const submitReview = () =>
    !reviewWithIdExists
      ? createReview(adherenceReviewCreateOrUpdateOptions)
      : updateReview(adherenceReviewCreateOrUpdateOptions);

  useEffect(() => {
    if (checkReviewExistenceQuery?.data?.adherenceReviewFindOne?.requestId !== undefined) {
      handleReviewExistence();
    }
  }, [checkReviewExistenceQuery.data]);

  if (
    checkReviewExistenceQuery.loading ||
    emptyFeedbackQuesitonsQuery.loading ||
    !reviewSubmission
  )
    return <Loading />;

  return (
    <PageShell>
      <ContentSection
        title={
          <Stack direction="column" alignSelf="flex-start" gap={10}>
            <Typography variant="h2">Review Submission</Typography>
          </Stack>
        }
        maxWidth="lg"
      >
        <Stack
          direction="row"
          justifyContent="center"
          sx={{ my: (theme) => theme.spacing(24) }}
          spacing={8}
        >
          {!reviewWithIdExists && (
            <Box>
              <Typography variant="h4">Ready to submit your review?</Typography>
              <Typography variant="caption">
                <Grid container justifyContent='stretch' >
                  {reviewCriteriaQuery.data?.adherenceSubmissionOne &&
                    Object.entries(reviewState.answerState).map((answerStateItem) => (
                      <Grid size={{xs: 12, lg: 6}} key={answerStateItem[0]} my={4} display='flex' wrap='nowrap' justifyContent='flex-start' alignItems='center'>
                          {answerStateItem[1] ? (
                            <CheckIcon
                              sx={{ display: 'inline-flex', fill: (theme) => theme.palette.common.success, mr: 4 }}
                            />
                          ) : (
                            <XIcon
                              sx={{ fill: (theme) => theme.palette.common.error, mr: 4 }}
                            />
                          )}{' '}
                          <Typography sx={{ fontSize: 24, display: 'inline-flex', textAlign: 'left' }} variant='body1'>
                          {reviewCriteriaQuery.data?.adherenceSubmissionOne.criteria.find(
                            (crit: { question_key: string; question: string }) =>
                              crit.question_key === answerStateItem[0]
                          )?.question || answerStateItem[0]}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
              </Typography>
              <Box sx={{ marginTop: (theme) => theme.spacing(16) }}>
                <KbdShortcut char="enter" onTap={() => submitReview()}>
                  <Button onClick={() => submitReview()}>Submit Review</Button>
                </KbdShortcut>
                <KbdShortcut char="esc" onTap={deleteExistingReview}>
                  <Button
                    sx={{ ml: (theme) => theme.spacing(8) }}
                    onClick={deleteExistingReview}
                  >
                    Back to Review
                  </Button>
                </KbdShortcut>
              </Box>
            </Box>
          )}
          {reviewWithIdExists && (
            <Box sx={{ marginTop: (theme) => theme.spacing(12) }}>
              <Typography variant="h4">A review with this ID already exists.</Typography>
              <Typography variant="h4">Update the existing review with this one?</Typography>
              <Box sx={{ marginTop: (theme) => theme.spacing(12) }}>
                <KbdShortcut char="y" onTap={() => submitReview()}>
                  <Button onClick={() => submitReview()}>Yes</Button>
                </KbdShortcut>
                <KbdShortcut char="n" onTap={() => clearStorageAndRedirect()}>
                  <Button
                    onClick={() => clearStorageAndRedirect()}
                    sx={{ ml: (theme) => theme.spacing(10) }}
                  >
                    No
                  </Button>
                </KbdShortcut>
              </Box>
              <Box sx={{ marginTop: (theme) => theme.spacing(12) }}>
                <KbdShortcut char="x" onTap={deleteExistingReview}>
                  <Button onClick={deleteExistingReview}>Delete and re-review</Button>
                </KbdShortcut>
              </Box>
            </Box>
          )}
        </Stack>
      </ContentSection>
    </PageShell>
  );
}
