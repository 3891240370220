import React, { Suspense, ReactNode } from 'react';
import { Route, Routes, useLocation, Navigate, Outlet } from 'react-router-dom';
import { OemTheme } from 'assets/styles/themes/functions';
import { PrivateRoute } from './PrivateRoute';
import usePageTitle from 'suites/sterling/app/hooks/usePageTitle';
import { useLanguage } from 'components/util/Language';
import { DownloadRoute } from 'components/util/DownloadRoute';

const AnalyticsDashboard = React.lazy(
  () => import('views/pages/analytics/analytics-dashboard/AnalyticsDashboard')
);

const SearchResults = React.lazy(() => import('views/pages/search/Search.Results'));
const SearchAdmin = React.lazy(() => import('views/pages/admin/search/SEOGeneration'));

const EducationCentre = React.lazy(
  () => import('views/pages/education-centre/EducationCentre')
);

const ProgramUpdates = React.lazy(() => import('views/pages/program-updates/ProgramUpdates'));
const PostDetails = React.lazy(() => import('views/pages/post-details/PostDetails'));
const Notifications = React.lazy(() => import('views/pages/notifications/Notifications'));

const ExecutiveSummary = React.lazy(
  () => import('views/pages/admin/executive-summary/ExecutiveSummary')
);

const ExecutiveDashboard = React.lazy(
  () => import('suites/portal/pages/executive/ExecutiveDashboard')
);

const PreferredVendors = React.lazy(
  () => import('views/pages/preferred-vendors/PreferredVendors')
);
const LighthouseQuestions = React.lazy(
  () => import('views/pages/admin/lighthouse-questions/LighthouseQuestions')
);
const AnalyticsManagement = React.lazy(
  () => import('views/pages/analytics/analytics-management/AnalyticsManagement')
);
const Rewards = React.lazy(() => import('views/pages/admin/rewards/Rewards'));
const AnalyticsPlayground = React.lazy(
  () => import('views/pages/admin/analytics/AnalyticsPlayground')
);
const Dashboard = React.lazy(
  () => import('views/pages/my-performance/performance-dashboard/PerformanceDashboard')
);
const PerformanceReport = React.lazy(
  () => import('views/pages/my-performance/performance-report/PerformanceReport')
);
const CategoryDashboard = React.lazy(
  () => import('views/pages/management-report/category-dashboard/CategoryDashboard')
);
const SummaryReport = React.lazy(
  () => import('views/pages/management-report/summary-report/SummaryReport')
);

// const Home = React.lazy(() => import('views/pages/home/Home'));

const CreateNewAccount = React.lazy(() => import('views/pages/admin/account-management'));

const Resources = React.lazy(() => import('../views/pages/coe-standards/Resources'));

const ManagementDashboard = React.lazy(
  () => import('views/pages/management-report/management-dashboard/ManagementDashboard')
);

const PackagedVendorDashboard = React.lazy(
  () => import('suites/portal/pages/vendor-dashboard/PackagedVendorDashboard')
);

const ProgressReport = React.lazy(() => import('views/pages/progress-report/ProgressReport'));
const ProgressHome = React.lazy(() => import('views/pages/progress-report/ProgressHome'));

const withSuspense = (node: ReactNode) => {
  return <Suspense fallback={null}>{node}</Suspense>;
};

export interface ClientRoute {
  title: string;
  titleFr: string;
  itemtype?: string;
  path: string;
  pathFr: string;
  order: number;
  content?: string;
  component: () => JSX.Element;
  private?: boolean;
  featureName?: string;
  noContent?: boolean;
  background?: string;
  hideNavLink?: boolean;
  children: ClientRoute[];
}

const myPerformanceChildren: ClientRoute[] = [
  {
    path: 'dashboard',
    pathFr: 'dashboard',
    title: 'Dashboard',
    titleFr: 'Planche de bord',
    order: 0,
    component: () => withSuspense(<Dashboard />),
    private: true,
    children: [],
  },
  {
    path: 'performance-report',
    pathFr: 'performance-report',
    title: 'Performance Report',
    titleFr: 'Rapport de performance',
    order: 1,
    component: () => withSuspense(<PerformanceReport />),
    private: true,
    children: [],
  },
  {
    path: 'dashboard/:unitCode/:assessmentCode?',
    pathFr: 'dashboard/:unitCode/:assessmentCode?',
    title: 'Dashboard',
    titleFr: 'Planche de bord',
    order: 0,
    component: () => withSuspense(<Dashboard />),
    hideNavLink: true,
    private: true,
    children: [],
  },
  {
    path: 'performance-report/:unitCode/:assessmentCode?/:categoryCode?',
    pathFr: 'performance-report/:unitCode/:assessmentCode?/:categoryCode?',
    title: 'Performance Report',
    titleFr: 'Rapport de performance',
    order: 0,
    component: () => withSuspense(<PerformanceReport />),
    hideNavLink: true,
    private: true,
    children: [],
  },
];

const managementReportChildren: ClientRoute[] = [
  {
    path: 'module-dashboard',
    pathFr: 'module-dashboard',
    title: 'Module Dashboard',
    titleFr: 'Tableau de bord des module',
    order: 1,
    component: () => withSuspense(<CategoryDashboard />),
    private: true,
    children: [],
  },
  {
    path: 'management-dashboard',
    pathFr: 'management-dashboard',
    title: 'Management Dashboard',
    titleFr: 'Tableau de bord de gestion',
    order: 0,
    component: () => withSuspense(<ManagementDashboard />),
    private: true,
    children: [],
  },
  {
    path: 'summary-report',
    pathFr: 'summary-report',
    title: 'Summary Report',
    titleFr: 'Rapport sommaire',
    order: 3,
    component: () => withSuspense(<SummaryReport />),
    private: true,
    children: [],
  },
  {
    path: 'vendor-dashboard',
    pathFr: 'vendor-dashboard',
    title: 'Vendor Dashboard',
    titleFr: 'Tableau de bord du fournisseur',
    order: 2,
    component: () => withSuspense(<PackagedVendorDashboard />),
    private: true,
    children: [],
  },
];

const superAdminChildren: ClientRoute[] = [
  {
    path: 'create-account',
    pathFr: 'create-account',
    itemtype: 'standards',
    title: 'Account Management',
    titleFr: '[Fr] Account Management',
    order: 0,
    component: () => withSuspense(<CreateNewAccount />),
    private: true,
    noContent: false,
    children: [],
  },
  {
    path: 'analytics-dashboard',
    pathFr: 'analytics-dashboard',
    itemtype: 'standards',
    title: 'Analytics Playground',
    titleFr: '[Fr] Analytics Playground',
    order: 0,
    component: () => withSuspense(<AnalyticsPlayground />),
    private: true,
    noContent: false,
    children: [],
  },
  {
    path: 'search-admin',
    pathFr: 'search-admin',
    itemtype: 'search',
    title: 'Search Playground',
    titleFr: '[Fr] Search Playground',
    order: 0,
    component: () => withSuspense(<SearchAdmin />),
    private: true,
    noContent: false,
    children: [],
  },
  {
    path: 'executive-summary',
    pathFr: 'executive-summary',
    itemtype: 'standards',
    title: 'Executive Summary',
    titleFr: '[Fr] Executive Summary',
    order: 1,
    component: () => withSuspense(<ExecutiveSummary />),
    private: true,
    noContent: false,
    children: [],
  },
  {
    path: 'executive-dashboard',
    pathFr: 'executive-dashboard',
    itemtype: 'standards',
    title: 'Executive Dashboard',
    titleFr: 'Tableau de bord exécutif',
    order: 2,
    component: () => withSuspense(<ExecutiveDashboard />),
    private: true,
    noContent: false,
    children: [],
  },
  {
    path: 'lighthouse-questions',
    pathFr: 'lighthouse-questions',
    itemtype: 'standards',
    title: 'Lighthouse v. Questions',
    titleFr: '[Fr] Lighthouse v. Questions',
    order: 3,
    component: () => withSuspense(<LighthouseQuestions />),
    private: true,
    noContent: false,
    children: [],
  },
];

const analyticsChildren: ClientRoute[] = [
  {
    path: 'dashboard',
    pathFr: 'dashboard',
    itemtype: 'standards',
    title: 'Dashboard',
    titleFr: 'Planche de bord',
    order: 0,
    component: () => withSuspense(<AnalyticsDashboard />),
    private: true,
    noContent: false,
    children: [],
  },
  {
    path: 'management',
    pathFr: 'management',
    itemtype: 'standards',
    title: 'Management',
    titleFr: 'Gestion',
    order: 1,
    component: () => withSuspense(<AnalyticsManagement />),
    private: true,
    noContent: false,
    children: [],
  },
];

export const clientRoutes: ClientRoute[] = [
  {
    path: '',
    pathFr: '/fr/',
    itemtype: 'home',
    title: 'Home',
    titleFr: 'Page d’accueil',
    order: 0,
    component: () => withSuspense(<ProgressHome />),
    private: false,
    noContent: false,
    children: [],
  },
  {
    path: 'program-updates',
    pathFr: 'program-updates',
    itemtype: 'latest-updates',
    title: 'Program Updates',
    titleFr: 'Mises à jour sur le programme',
    order: 0,
    background: OemTheme.colour.background.primary,
    component: () => withSuspense(<ProgramUpdates />),
    private: false,
    noContent: false,
    children: [],
  },
  {
    path: 'admin',
    pathFr: 'admin',
    itemtype: 'management',
    title: 'Administrative',
    titleFr: '[Fr] Administrative',
    order: 0,
    component: () => <span />,
    private: true,
    noContent: true,
    children: superAdminChildren.sort((chA, chB) => chA.order - chB.order),
  },
  {
    path: 'management-report',
    pathFr: 'management-report',
    itemtype: 'management',
    title: 'Management Report',
    titleFr: 'Rapport de direction',
    order: 1,
    component: () => <span />,
    private: true,
    noContent: true,
    children: managementReportChildren.sort((chA, chB) => chA.order - chB.order),
  },
  {
    path: 'my-performance',
    pathFr: 'my-performance',
    itemtype: 'performance',
    title: 'My Performance',
    titleFr: 'Ma performance',
    order: 2,
    component: () => <span />,
    private: true,
    noContent: true,
    children: myPerformanceChildren.sort((chA, chB) => chA.order - chB.order),
  },
  {
    path: 'analytics',
    pathFr: 'analytics',
    itemtype: 'analytics',
    title: 'Analytics',
    titleFr: 'Analytics',
    order: 2,
    component: () => <span />,
    private: true,
    noContent: true,
    children: analyticsChildren.sort((chA, chB) => chA.order - chB.order),
  },
  {
    path: 'education',
    pathFr: 'education',
    itemtype: 'education',
    title: 'Education & Training',
    titleFr: 'Éducation et formation',
    order: 7,
    component: () => withSuspense(<EducationCentre />),
    private: false,
    noContent: false,
    children: [],
  },

  {
    path: 'notifications',
    pathFr: 'notifications',
    itemtype: 'latest-updates',
    title: 'Notifications',
    titleFr: 'Notifications',
    order: 1,
    component: () => withSuspense(<Notifications />),
    private: true,
    noContent: false,
    hideNavLink: true,
    children: [],
  },
  {
    path: 'search',
    pathFr: 'search',
    itemtype: 'search',
    title: 'Search Results',
    titleFr: 'Résultats de recherche',
    order: 0,
    component: () => withSuspense(<SearchResults />),
    private: true,
    noContent: false,
    hideNavLink: true,
    children: [],
  },
  {
    path: 'preferred-vendors',
    pathFr: 'preferred-vendors',
    itemtype: 'preferred-vendors',
    title: 'Preferred Vendors',
    titleFr: 'Fournisseurs privilégiés',
    order: 1,
    component: () => withSuspense(<PreferredVendors />),
    private: true,
    noContent: false,
    children: [],
  },
  {
    path: 'post-details/:postId',
    pathFr: 'post-details/:postId',
    itemtype: 'latest-updates',
    title: 'Post Details',
    titleFr: 'Détails du Post',
    order: 1,
    component: () => withSuspense(<PostDetails />),
    private: false,
    noContent: false,
    background: OemTheme.colour.background.primary,
    hideNavLink: true,
    children: [],
  },
  {
    path: 'webinar/:postId',
    pathFr: 'webinar/:postId',
    itemtype: 'latest-updates',
    title: 'Education Post Details',
    titleFr: 'Détails du post éducatif',
    order: 1,
    component: () => withSuspense(<PostDetails />),
    private: false,
    noContent: false,
    background: OemTheme.colour.background.primary,
    hideNavLink: true,
    children: [],
  },
  {
    path: 'program-update/:postId',
    pathFr: 'program-update/:postId',
    itemtype: 'latest-updates',
    title: 'Program Update',
    titleFr: 'Mises à jour sur le Programme',
    order: 1,
    component: () => withSuspense(<PostDetails />),
    private: false,
    noContent: false,
    background: OemTheme.colour.background.primary,
    hideNavLink: true,
    children: [],
  },
  {
    path: 'business-insight/:postId',
    pathFr: 'business-insight/:postId',
    itemtype: 'business-insights',
    title: 'Business Insights',
    titleFr: 'Perspectives Stratégiques',
    order: 1,
    component: () => withSuspense(<PostDetails />),
    private: false,
    noContent: false,
    background: OemTheme.colour.background.primary,
    hideNavLink: true,
    children: [],
  },
  {
    path: 'rewards',
    pathFr: 'rewards',
    itemtype: 'rewards',
    title: 'Rewards',
    titleFr: 'Récompenses',
    order: 5,
    component: () => withSuspense(<Rewards />),
    private: false,
    noContent: false,
    background: OemTheme.colour.background.primary,
    children: [],
  },
  {
    path: 'progress-report',
    pathFr: 'progress-report',
    itemtype: 'progress-report',
    title: 'Progress Report',
    titleFr: 'Rapport de Progrès',
    order: 5,
    component: () => withSuspense(<ProgressReport />),
    private: false,
    noContent: false,
    background: OemTheme.colour.background.primary,
    hideNavLink: true,
    children: [],
  },
];

function HookWrappedComponent({ route }: { route: ClientRoute }) {
  const lang = useLanguage();
  const RouteComponent = route.component;
  usePageTitle({ EN: route.title, FR: route.titleFr }[lang]);
  return (
    <>
      <RouteComponent />
      <Outlet />
    </>
  );
}

function ClientRoutes(props: { routes: ClientRoute[] }): JSX.Element[] {
  const { routes } = props;

  const mapRoute = (route: ClientRoute): JSX.Element => {
    // When noContent is true and children exist, render an Outlet and an index
    // route that performs the redirection.
    if (route.noContent && route.children.length > 0) {
      return (
        <Route key={route.path} path={route.path} element={<Outlet />}>
          {/* When the parent's path exactly matches, redirect to the first child */}
          <Route index element={<Navigate to={route.children[0].path} replace />} />
          {route.children.map(mapRoute)}
        </Route>
      );
    }

    // Otherwise, build the normal element--wrap with PrivateRoute if necessary.
    const element = route.private ? (
      <PrivateRoute key={route.path + '-private-route'}>
        <HookWrappedComponent route={route} />
      </PrivateRoute>
    ) : (
      <HookWrappedComponent route={route} />
    );

    if (route.children.length > 0) {
      return (
        <Route key={route.path} path={route.path} element={element}>
          {route.children.map(mapRoute)}
        </Route>
      );
    }

    return <Route key={route.path} path={route.path} element={element} />;
  };

  return routes.map(mapRoute);
}

function ResourceRoutes(props: { routes: string[] }) {
  // Create routes for each pattern
  return props.routes.flatMap((routePattern, index) => {
    // Handle patterns with optional fr prefix
    if (routePattern.startsWith('(/fr)?')) {
      const basePath = routePattern.replace('(/fr)?', '').replace(/\*$/, '');

      return [
        // English version
        <Route
          key={`resource-route-en-${index}`}
          path={`${basePath}/*`}
          element={withSuspense(<Resources />)}
        />,
        // French version
        <Route
          key={`resource-route-fr-${index}`}
          path={`fr${basePath}/*`}
          element={withSuspense(<Resources />)}
        />,
      ];
    } else {
      // Regular path without language prefix
      const basePath = routePattern.replace(/\*$/, '');

      return [
        <Route
          key={`resource-route-${index}`}
          path={`${basePath}/*`}
          element={withSuspense(<Resources />)}
        />,
      ];
    }
  });
}

export function SterlingRoutes() {
  const location = useLocation();
  const isDownloadable = /(\.pdf|\.zip)$/.test(location.pathname);
  const lang = useLanguage();

  return (
    <Routes location={location}>
      {ResourceRoutes({
        routes: [
          '(/fr)?/standards/*',
          '(/fr)?/program-standards-2021/*',
          '(/fr)?/support/*',
          '(/fr)?/changelog/*',
          '(/fr)?/recognition/*',
          '(/fr)?/preferred-vendors/*',
          '(/fr)?/resources/*',
          '(/fr)?/education-materials/*',
          '(/fr)?/latest-updates/*',
        ],
      })}
      <Route path="fr/*" element={<Outlet />}>
        {ClientRoutes({ routes: clientRoutes })}
      </Route>
      {ClientRoutes({ routes: clientRoutes })}
      {!isDownloadable && (
        <Route path="*" element={<Navigate to={{ EN: '/', FR: '/fr/' }[lang]} replace />} />
      )}
      {isDownloadable && (
        <Route path="*" element={<DownloadRoute fileUrl={location.pathname} />} />
      )}
    </Routes>
  );
}
