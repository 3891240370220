import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { ClientRoute } from 'routing/Routes';
import { useLanguage } from 'components/util/Language';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import { normalizePath } from 'utility';

const getNextPath = (content: ClientRoute): string => {
  if (content.noContent && content.children?.[0]) {
    return getNextPath(content.children[0]);
  }
  return content.path;
};

export function NavBuilder(props: {
  setMobileOpen?: (arg: boolean) => void;
  content: ClientRoute;
  nestLevel: number;
  parentRoute?: ClientRoute;
}) {
  const { content, nestLevel, setMobileOpen, parentRoute } = props;
  const location = useLocation();
  const lang = useLanguage();
  const currentPath = lang === 'FR' && content.pathFr ? content.pathFr : content.path;

  const highlighted = location.pathname.includes(currentPath);

  // Check if the current path is already a full path (e.g., "standards/our-brand")
  const isFullPath = currentPath.includes('/');

  // Normalize both paths
  const normalizedParent = parentRoute ? normalizePath(parentRoute.path) : '';
  const normalizedCurrent = normalizePath(currentPath);
  const normalizedChild =
    content.noContent && content.children?.length > 0
      ? normalizePath(content.children[0].path).split('/').pop() + '/'
      : ''; // normalize and get last path


  // Build fully qualified path while avoiding segment duplication
  let fullyQualifiedPath;
  if (parentRoute) {
    // If current path already contains parent path or is already a full path, don't prepend parent
    if (normalizedCurrent.startsWith(normalizedParent) || isFullPath) {
      fullyQualifiedPath =
        lang === 'FR'
          ? `/fr/${normalizedCurrent}/${normalizedChild ?? ''}`
          : `/${normalizedCurrent}/${normalizedChild ?? ''}`;
    } else {
      fullyQualifiedPath =
        lang === 'FR'
          ? `/fr/${normalizedParent}/${normalizedCurrent}/`
          : `/${normalizedParent}/${normalizedCurrent}/`;
    }
  } else {
    fullyQualifiedPath =
      lang === 'FR' ? `/fr/${normalizedCurrent}/` : `/${normalizedCurrent}/`;
  }

  const [open, setOpen] = React.useState(highlighted);

  useEffect(() => {
    if (highlighted) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [location.pathname, highlighted]);

  const renderLink = (path: string, hasChildren: boolean) => (
    <Link
      to={fullyQualifiedPath}
      className={clsx(
        'nav-link',
        'nav-link-sub',
        `nested${nestLevel}`,
        open && 'nested-selected'
      )}
      onClick={setMobileOpen && !hasChildren ? () => setMobileOpen(false) : () => null}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: lang === 'FR' && content.titleFr ? content.titleFr : content.title,
        }}
      />
    </Link>
  );

  if (content.children && content.children.length > 0) {
    const nextPath = getNextPath(content);

    return (
      <>
        {renderLink(nextPath, true)}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {content.children.map((childContent, index) => (
              <NavBuilder
                key={index}
                content={childContent}
                nestLevel={nestLevel + 1}
                setMobileOpen={setMobileOpen}
                parentRoute={content}
              />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return renderLink(currentPath, false);
}
