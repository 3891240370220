import React, { useLayoutEffect } from 'react';
import { Loading, ErrorHandle } from 'components';
import { TestPageLoad } from 'components/general/TestPageLoad';
import { Navigate } from 'react-router-dom';
import { handleEnterKey } from 'utility';
import { useResourcesModel } from './model.AdherenceResources';
import { AdherenceResourcesDiv } from './styled.AdherenceResources';
import { gql } from '@apollo/client';

// eslint-disable-next-line
gql`
  query adherenceResources($path: String!, $language: String!) {
    adherenceContentOne(path: $path, language: $language) {
      title
      content
      itemtype
      parent
      order
      language
      next {
        path
        title
        titleFr
        noContent
        children {
          path
          title
          titleFr
        }
      }
      previous {
        path
        title
        titleFr
        noContent
        children {
          path
          title
          titleFr
        }
      }
    }
  }
`;

export function AdherenceResources() {
  const {
    oemClassName,
    ImageModal,
    handleClick,
    resourceContent,
    resourceQuery,
  } = useResourcesModel();

  useLayoutEffect(() => {
      const urlHash = window.location.hash;

      if (urlHash) {
        // Timeout is to wait until content has rendered
        // We cannot connect it directly with the components in the DOM because they are rendered dynamically
        // and we don't know what they look like until the DB content is queried
        setTimeout(() => {
          const element = document.querySelector(urlHash);
          element?.scrollIntoView();
          window.scrollBy(0, -150);
        }, 100);
      }

  });

  if (resourceQuery.loading) return <Loading />;
  if (resourceQuery.error) {
    return <ErrorHandle gqlError={resourceQuery.error} />;
  }
  if (!resourceQuery.data) {
    return <ErrorHandle error="resourceQuery data is missing" />;
  }
  if (!resourceQuery.data.adherenceContentOne) {
    return <Navigate to="/adherence" />;
  }

  return (
    <>
      <ImageModal />
      <AdherenceResourcesDiv className={`content guide ${oemClassName}`}>
        <TestPageLoad />
        <div
          id="content-resources"
          onClick={handleClick}
          onKeyPress={(e) => handleEnterKey(e, () => handleClick(e))}
          aria-label="Link to next page"
          role="button"
          tabIndex={0}
          dangerouslySetInnerHTML={{ __html: resourceContent }}
        />
      </AdherenceResourcesDiv>
    </>
  );
}
