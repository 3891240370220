import React, { ComponentProps, useEffect, useState, useCallback } from 'react';
import {
  ClickAwayListener,
  Popper,
  Paper,
  MenuItem,
  InputBase,
  IconButton,
  Chip,
  Typography,
  Stack,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { SearchMenuSection, useSearch } from './models/model.Search';
import { useLanguage } from 'components/util/Language';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RestoreIcon from '@mui/icons-material/Restore';
import { getUrlLanguage } from 'utility';

interface SearchMenuProps {
  searchValue?: string;
  label?: string;
  fieldWidth?: number;
  searchTermClear: () => void;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const searchResultsPageRedirect = (_searchValue: string) => {
  const currentUrl = window.location.pathname;
  const lang = getUrlLanguage(currentUrl);
  const newUrl = currentUrl.endsWith('?') ? currentUrl.slice(0, -1) : currentUrl;
  window.location.replace(newUrl);
  const encodedUrl = encodeURI(
    `${{ EN: '', FR: '/fr' }[lang]}/search?v=${encodeURIComponent(_searchValue ?? '')}`
  )
  window.location.assign(encodedUrl);
};

function SearchItemIcon({
  category,
  ...rest
}: { category: string } & ComponentProps<typeof RestoreIcon>): JSX.Element {
  return category === 'History' ? (
    <RestoreIcon {...rest} />
  ) : category === 'Pages' ? (
    <ListAltIcon {...rest} />
  ) : (
    <></>
  );
}

function SearchMenu({
  label,
  searchValue,
  onChange,
  searchTermClear,
  contentResultsState,
  suggestionResultsState,
  searchHistoryState,
  searchPending,
  onClearClick,
  fieldWidth = 350,
  handleSearchResultClick,
}: SearchMenuProps & ReturnType<typeof useSearch>): JSX.Element {

  const lang = useLanguage();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const searchFieldRef = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setAnchorEl(node);
    }
  }, []);

  const closeMenu = () => {
    setOpen(false);
  };

  const openMenu = () => {
    setOpen(true);
  };

  const handleClickAway = () => {
    closeMenu();
  };

  const [menuOptions, setMenuOptions] = useState<SearchMenuSection[]>([]);

  const handleMenuOpen = () => {
    if (menuOptions.length === 0) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  const updateMenuOptions = () => {
    // Add all menu section then filter out those without items
    const newOptionsState = [
      suggestionResultsState,
      searchHistoryState,
      contentResultsState,
    ].filter((section) => section !== undefined) as SearchMenuSection[];

    setMenuOptions(newOptionsState);
  };

  const handleSearchSelected = (
    option: { label: string; value: string },
    searchKey?: string
  ) => {
    handleSearchResultClick(searchKey ?? option.label, option);
    handleClickAway();
  };

  const handleSearchRequested = (suggestion?: string) => {
    if (
      (suggestion !== undefined && suggestion.length > 1) ||
      (searchValue !== undefined && searchValue.length > 1)
    ) {
      searchResultsPageRedirect(suggestion ?? searchValue ?? '');
    }
  };

  useEffect(() => {
    updateMenuOptions();
  }, [suggestionResultsState?.items, searchHistoryState?.items, contentResultsState?.items]);

  useEffect(() => {
    if (menuOptions.length === 0) {
      closeMenu();
    }
    if (!searchPending && searchValue !== '') {
      handleMenuOpen();
    }
  }, [menuOptions.length, searchPending]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Paper
        component="form"
        ref={searchFieldRef}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: fieldWidth,
          backgroundColor: 'white',
          borderRadius: '8px',
          border: '1px solid black',
          borderRightWidth: '0px',
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1, px: 1, py: 2 }}
          placeholder={label}
          onClick={handleMenuOpen}
          onChange={onChange}
          value={searchValue}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              handleSearchRequested();
            }
          }}
          inputProps={{
            'aria-label': 'content search bar',
            sx: {
              padding: '0px 0px 2px 4px',
              mt: '-2px',
              '::placeholder': { fontSize: 14 },
            },
          }}
        />
        {!!searchValue && (
          <IconButton
            sx={{ display: 'flex' }}
            aria-label="clear button"
            disableRipple
            onClick={() => {
              onClearClick();
              searchTermClear();
              setMenuOptions([]);
            }}
          >
            <Chip
              sx={{
                alignItems: 'center',
                height: 0,
                pt: 2.5,
                ml: 0,
                mr: 2,
                pb: 3.75,
                mt: 0.5,
                px: 0,
              }}
              label={
                <Typography sx={{ fontSize: '14px !important', m: 0 }}>
                  {{ EN: 'Clear', FR: 'Effacer' }[lang]}
                  <CloseIcon
                    sx={{
                      height: '16px',
                      width: '16px',
                      transform: 'translateY(20%)',
                      m: 0,
                      p: 0,
                    }}
                  />
                </Typography>
              }
            />
          </IconButton>
        )}
        <IconButton
          type="button"
          onClick={(event) => {
            event.preventDefault();
            handleSearchRequested();
          }}
          sx={{
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            p: 2,
            width: 50,
            height: 50,
            boxShadow:
              '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
            borderRadius: '0 8px 8px 0',
            ':hover': {
              backgroundColor: 'secondary.main',
              color: 'primary.contrastText',
            },
          }}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <Popper open={open} anchorEl={anchorEl} placement="bottom-start">
          <Paper
            sx={{
              backgroundColor: 'white',
              minWidth: fieldWidth,
              width: 'fit-content',
              borderRadius: '8px',
              mt: 4,
              pb: 4,
              maxHeight: '85vh',
              overflowY: 'auto',
              '& .MuiMenuItem-root': {
                width: '100%',
              },
            }}
          >
            {menuOptions?.map((category) =>
              category.items.length > 0 ? (
                <Box key={category.optionType.EN} sx={{ width: '100%', mt: 3 }}>
                  <Typography variant="body2" sx={{ ml: 4, mb: 2, color: '#333' }}>
                    {category.optionType[lang]}
                  </Typography>
                  {category?.items?.slice(0, 5).map((option) => (
                    <Stack
                      direction="row"
                      m={0}
                      alignItems="center"
                      key={`${category.optionType.EN}_${option.value}`}
                    >
                      <MenuItem
                        onMouseDown={() =>
                          category.optionType.EN === 'Suggestions'
                            ? handleSearchRequested(option.label)
                            : handleSearchSelected(option, searchValue)
                        }
                      >
                        <SearchItemIcon
                          category={category.optionType.EN}
                          sx={{ width: '0.75em', height: '0.75em', mr: 2 }}
                        />
                        {option.label}{' '}
                        {option?.ancestors?.at(-1)?.title && (
                          <Typography variant="button" sx={{ ml: 1, color: '#777' }}>
                            {{ EN: 'in ', FR: 'in ' }[lang]}
                            {option?.ancestors?.at(-1)?.title}
                          </Typography>
                        )}
                      </MenuItem>
                    </Stack>
                  ))}
                </Box>
              ) : (
                null
              )
            )}
            {menuOptions.reduce(
              (acc, category) =>
                category.optionType?.EN !== 'History' ? acc + category.items.length : 0,
              0
            ) >= 10 && (
              <Box sx={{ width: '100%', mt: 4 }}>
                <Typography variant="body2" sx={{ ml: 4, color: '#333 !important' }}>
                  {{ EN: 'All Content', FR: 'Tout le contenu' }[lang]}
                </Typography>
                <MenuItem onClick={() => searchResultsPageRedirect(searchValue ?? '')}>
                  {{ EN: 'View all results', FR: 'Voir tous les résultats' }[lang]}
                </MenuItem>
              </Box>
            )}
          </Paper>
        </Popper>
      </Paper>
    </ClickAwayListener>
  );
}

export default SearchMenu;
