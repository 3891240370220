import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ErrorHandle, Loading } from 'components';
import { useQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import React from 'react';
import { useLanguage } from 'suites/sterling/app/context/language';
import { PageShell } from 'suites/sterling/molecules/shells/page/PageShell';
import { ContentSection } from 'suites/sterling/molecules/sections/content/ContentSection';
import { Adherence_Cards } from 'generated';
import { SummaryCard } from './components/SummaryCard';
import {
  BRAND_COMPONENTS_ALL_QUERY,
  MEDIA_TYPES_ALL_QUERY,
} from './queries.AdherenceStandards';

function AdherenceStandards(props: { query: DocumentNode; title: React.ReactNode }) {
  const lang = useLanguage();
  const { loading, error, data } = useQuery(props.query, {
    variables: { language: lang },
  });

  if (loading) return <Loading />;
  if (error) return <ErrorHandle componentName="AdherenceStandards" gqlError={error} />;

  return (
    <PageShell>
      <ContentSection title={props.title}>
        <Stack
          gap={6}
          direction="row"
          component={Grid}
          container
          justifyContent="center"
          alignItems={{ xs: 'center', lg: 'stretch' }}
          sx={{
            width: '100%',
            paddingBottom: (theme) => theme.spacing(12),
            '& > *': { width: { xs: '70%', xl: '40%' }, display: 'flex' },
          }}
        >
          {data?.adherenceCardsMany.map((card: Adherence_Cards) => (
            <SummaryCard
              key={card.key}
              header={card.title}
              subheader={card.link_label}
              image={card.image}
              link_url={card.link_url}
              summary={card.description}
            />
          ))}
        </Stack>
      </ContentSection>
    </PageShell>
  );
}

export function AdherenceMediaTypeStandards() {
  const lang = useLanguage();

  return (
    <AdherenceStandards
      query={MEDIA_TYPES_ALL_QUERY}
      title={
        <>
          <Typography variant="h3">
            {{ EN: 'Media channels.', FR: 'Canaux de médias.' }[lang]}
          </Typography>

        </>
      }
    />
  );
}
export function AdherenceBrandComponentStandards() {
  const lang = useLanguage();

  return (
    <AdherenceStandards
      query={BRAND_COMPONENTS_ALL_QUERY}
      title={
        <>
          <Typography variant="h3">
            {{ EN: 'Brand Components.', FR: 'Composants de la marque.' }[lang]}
          </Typography>

        </>
      }
    />
  );
}
