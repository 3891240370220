import { useMarkAllAsReadMutation, useMarkAllAsUnreadMutation } from 'generated';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotificationsQuery } from './query.Notifications';
import { Analytics } from 'components/util/AnalyticEvents';

export const DEFAULT_SHOW_MORE = 4;

// Intentionally want the return type inferred
// eslint-disable-next-line
export const useNotifications = () => {
  const navigate = useNavigate();

  const {
    refetchSeen,
    notifications,
    readNotifications,
    unreadNotifications,
    unreadNotificationsCount,
    loading,
    error,
    data,
  } = useNotificationsQuery();

  const [markAllAsRead] = useMarkAllAsReadMutation();
  const [markAllAsUnread] = useMarkAllAsUnreadMutation();

  const handleClick = (key: string) => {
    const notification = notifications.find((n) => n.key === key);
    Analytics.Track.Generic('Notification Clicked', {
      notification: notification?.button_label,
      notification_link: notification?.button_link,
    });
    if (notification && notification.button_label) {
      const visitUrl = () => {
        if (/:\/\//.test(`${notification.button_link}`)) {
          window.open(`${notification.button_link}`, '_blank');
        } else {
          navigate(`${notification.button_link}`);
        }
      };
      // If it's unread, mark it as read
      if (unreadNotifications.find((n) => n.key === notification.key)) {
        markAllAsRead({
          variables: {
            ids: [`${notification.key}`],
          },
        }).then(() => {
          refetchSeen().then(() => {
            visitUrl();
          });
        });
      } else {
        visitUrl();
      }
    }
  };

  const totalCount = unreadNotifications.length + readNotifications.length;

  const constrainToCount = (num: number) =>
    num > readNotifications.length ? readNotifications.length : num;

  const [showPrevious, setShowPrevious] = useState(constrainToCount(DEFAULT_SHOW_MORE));
  const showMore = () => setShowPrevious((state) => constrainToCount(state + 4));
  const showLess = () => setShowPrevious(constrainToCount(DEFAULT_SHOW_MORE));

  useEffect(() => {
    setShowPrevious(constrainToCount(DEFAULT_SHOW_MORE));
    // Only run whenever the number of read notifications changes
    // eslint-disable-next-line
  }, [data]);

  return {
    unreadNotifications,
    unreadNotificationsCount,
    readNotifications: readNotifications.slice(0, showPrevious),
    loading,
    error,
    totalCount,
    markAllAsRead: () =>
      markAllAsRead({ variables: { ids: unreadNotifications.map((n) => `${n.key}`) } }).then(
        () => {
          refetchSeen();
        }
      ),
    markAllAsUnread: () =>
      markAllAsUnread().then(() => {
        refetchSeen();
      }),
    showPrevious,
    readTotal: readNotifications.length,
    showMore,
    showLess,
    handleClick,
  };
};
