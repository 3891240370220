import { ErrorHandle, Loading } from 'components';
import { usePrivateRouteQuery } from 'generated';
import { gql } from '@apollo/client';
import { Navigate, useLocation, matchRoutes } from 'react-router-dom';
import { useLanguage } from 'components/util/Language';
import { ClientRoute, clientRoutes } from './Routes';

// eslint-disable-next-line
gql`
  query privateRoute($path: String!) {
    routePermission(path: $path)
  }
`;

/**
 * Recursively converts ClientRoute objects to the format expected by matchRoutes
 */
function createRouteObjects(routes: Array<ClientRoute>): Array<ClientRoute> {
  return routes.flatMap((route) => {
    const routeObject = {
      path: route.path,
      title: route.title,
      titleFr: route.titleFr,
      pathFr: route.pathFr,
      order: route.order,
      component: route.component,
      children:
        route.children && route.children.length > 0 ? createRouteObjects(route.children) : [],
    };

    // If this is a parent route with children, include an index route
    if (route.children && route.children.length > 0) {
      return [routeObject];
    }

    return routeObject;
  });
}

/**
 * Extracts the base route path without parameters from the current path
 * using matchRoutes from React Router v7
 */
function getBaseRoutePath(pathname: string): string {
  // Create route objects from clientRoutes for matching
  const routeObjects = createRouteObjects(clientRoutes);

  // Try to match the current path against defined routes
  const matches = matchRoutes(routeObjects, pathname);

  if (matches && matches.length > 0) {
    // Return the pattern of the matched route (without params)
    return matches[0].route.path || '';
  }

  // Fallback to simple regex replacement if no match is found
  // This removes everything after and including any segment that starts with ":"
  return pathname.replace(/\/:[^/]+/g, '');
}

export function PrivateRoute({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const lang = useLanguage();

  // Get the base route path without parameters using improved method
  const pathWithoutParams = getBaseRoutePath(location.pathname);

  const privateQuery = usePrivateRouteQuery({
    variables: { path: pathWithoutParams },
  });

  if (privateQuery.loading) return <Loading />;
  if (privateQuery.error) {
    return (
      <>
        <ErrorHandle gqlError={privateQuery.error} />
        <Navigate to={{ EN: '/', FR: '/fr/' }[lang]} replace />
      </>
    );
  }
  if (!privateQuery.data) {
    return <ErrorHandle error="Invalid data (PrivateRoute)." />;
  }

  const permission = privateQuery.data.routePermission;

  if (!permission) {
    return <Navigate to={{ EN: '/', FR: '/fr/' }[lang]} replace />;
  }

  return <>{children}</>;
}
