import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { StrictComplianceCase } from 'suites/compliance/app/business-logic';
import { CaseCard } from 'suites/compliance/components/cards/case/CaseCard';
import { useTranslation } from 'suites/sterling/app/hooks/useTranslation';
import { complianceAppContent as c } from 'suites/compliance/ComplianceApp.content';

export function ReporterInformationCard({ reporter }: StrictComplianceCase) {
  const t = useTranslation();

  return (
    <CaseCard title="Reporter Information">
      <Stack direction="column" spacing={2} component={Grid} size={{xs: 4}}>
        <Typography variant="body2">
          <strong>{t(c.formTerms.reporterName)}:</strong> {reporter.name}
        </Typography>

        <Typography variant="body2">
          <strong>{t(c.formTerms.reporterDealerCode)}:</strong> {reporter.dealerCode}
        </Typography>

        <Typography variant="body2">
          <strong>{t(c.formTerms.reporterEmail)}:</strong> {reporter.email}
        </Typography>
      </Stack>
    </CaseCard>
  );
}
