import {
  Drawer,
  Button,
  Box,
  Typography,
  Breadcrumbs,
  Divider,
  Link,
  Chip,
  List,
  ListItem,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';
import { Tabs } from '@base-ui-components/react';
import React, { ReactNode, useState, createContext } from 'react';
import { CurrentOem } from 'utility';
import { useLanguage } from 'components/util/Language';
import { KbdShortcut } from 'components/general/KbdShortcut';

interface PanelConfig {
  panelDepth: number;
  question: string;
}

interface PanelConfigHook {
  panelConfig: { panelDepth: number; question: string };
  setPanelConfig: React.Dispatch<React.SetStateAction<PanelConfig>>;
}
// https://react.dev/reference/react/useContext
export const PanelContext = createContext<PanelConfigHook | null>(null);

export function useBreadcrumbs() {
  function breadcrumbCreator(
    crumbMap: Record<number, string>,
    depth: number,
    changeHandler: (idx: number) => void
  ) {
    const crumbs = Object.values(crumbMap);
    return crumbs.slice(0, depth + 1).map((crumbText: string, idx: number) => (
      <Link
        key={idx}
        underline="hover"
        color="inherit"
        sx={{ cursor: 'pointer' }}
        onClick={() => changeHandler(idx)}
      >
        {crumbText}
      </Link>
    ));
  }
  function Breadcrumb(breadcrumbProps: {
    breadcrumbDepth: number;
    handleBreadcrumbDepthChange: (depth: number) => void;
  }) {
    const { breadcrumbDepth, handleBreadcrumbDepthChange } = breadcrumbProps;
    return (
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 8 }} id="breadcrumb" separator=" / ">
        {breadcrumbCreator(
          { 0: 'Brand Component Summary', 1: 'Second', 2: 'Third', 3: 'Forth' },
          breadcrumbDepth,
          handleBreadcrumbDepthChange
        )}
      </Breadcrumbs>
    );
  }

  return {
    breadcrumbCreator,
    Breadcrumb,
  };
}

interface StPanelProps {
  isSidePanelOpen: boolean;
  toggleSidePanel: () => void;
  children?: ReactNode | ReactNode[];
}

export function StPanel(props: StPanelProps) {
  const lang = useLanguage();
  const { isSidePanelOpen, toggleSidePanel, children } = props;
  const isMMSCAN = CurrentOem === 'MMSC';
  const closeButtonStyles = isMMSCAN
    ? { '& span': { height: '1px', fontSize: '16px', paddingBottom: '23px' } }
    : {};

  return (
    <Drawer
      open={isSidePanelOpen}
      anchor="right"
      sx={{
        width: { lg: '40%', xs: '95%' },
        marginLeft: { lg: '60%', xs: '5%' },
        '.MuiPaper-elevation16': {
          position: 'relative',
          backgroundColor: (theme) => theme.palette.background.default,
        },
      }}
      ModalProps={{ keepMounted: false, disableEscapeKeyDown: true }}
    >
      <Box textAlign="left" my={1} width="100%">
        <KbdShortcut
          char="esc"
          hideChar
          inline
          onTap={() => {
            toggleSidePanel();
          }}
        >
          <Button
            onClick={toggleSidePanel}
            variant="outlined"
            size="small"
            sx={{
              ...closeButtonStyles,
              fontWeight: 'bold',
              minWidth: 'unset',
              ml: 4,
              color: (theme) => theme.palette.text.primary,
              borderColor: 'transparent',
            }}
          >
            <CloseIcon
              sx={{
                width: '18px',
                mr: 1.5,
                strokeWidth: 1,
                stroke: (theme) => theme.palette.text.primary,
              }}
            />

            <span>{{ EN: 'Close', FR: 'Ferme' }[lang]}</span>
          </Button>
        </KbdShortcut>
      </Box>
      <Divider sx={{ borderColor: 'lightGrey' }} />
      {children}
    </Drawer>
  );
}

export function PortalPanel() {
  const [currentPanelDepth, setCurrentPanelDepth] = useState(0);
  const lang = useLanguage();
  const handleDepthChange = (depth: number) => {
    setCurrentPanelDepth(depth);
  };
  return (
    <>
      <Box padding={8}>
        <Typography variant="h4">Hyundai</Typography>
        <Grid container alignItems="center">
          <Grid mr={2}>
            <Typography>Sales Event: </Typography>
          </Grid>
          <Grid size="grow">
            <Chip
              variant="outlined"
              sx={{
                fontWeight: 700,
                color: (theme) => theme.palette.action.selected,
                border: (theme) => `3px ${theme.palette.action.selected} solid`,
              }}
              label="Breakaway Sales Event 2023"
            />
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ borderColor: 'lightGrey' }} />
      <Box padding={8}>
        {/* <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 8 }} id="breadcrumb" separator=" / ">
          {breadcrumbCreator(
            { 0: 'Brand Component Summary', 1: 'Second', 2: 'Third', 3: 'Forth' },
            currentPanelDepth,
            handleDepthChange
          )}
        </Breadcrumbs> */}
        <Tabs.Root
          defaultValue={1}
          value={currentPanelDepth}
          onValueChange={(smt) => handleDepthChange(smt as number)}
        >
          <Tabs.List>
            <Tabs.Tab value={0}>1</Tabs.Tab>
            <Tabs.Tab value={1}>2</Tabs.Tab>
            <Tabs.Tab value={2}>3</Tabs.Tab>
            <Tabs.Tab value={3}>4</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value={0}>
            <Typography variant="h4">Brand Component Summary</Typography>
            <Typography>
              {
                {
                  EN: `Below you will find the questions for each brand component that requires attention.`,
                  FR: ``,
                }[lang]
              }
            </Typography>
            <List>
              <ListItem
                sx={{
                  padding: 4,
                  backgroundColor: (theme) => theme.palette.background.paper,
                  border: ' 2px lightgrey solid',
                }}
              >
                Placeholder
              </ListItem>
            </List>
          </Tabs.Panel>
          <Tabs.Panel value={1}>
            <Typography variant="caption">Tab 2</Typography>
          </Tabs.Panel>
          <Tabs.Panel value={2}>
            <Typography variant="caption">Tab 3</Typography>
          </Tabs.Panel>
          <Tabs.Panel value={3}>
            <Typography variant="caption">Tab 4</Typography>
          </Tabs.Panel>
        </Tabs.Root>
      </Box>
    </>
  );
}

interface PanelConfig {
  panelDepth: number;
  question: string;
}

export function useStPanel() {
  const [isSidePanelOpen, setSidePanelOpen] = useState(false);
  const toggleSidePanel = () => {
    setSidePanelOpen(!isSidePanelOpen);
  };
  const breadcrumbRef = React.useRef(null);

  return {
    isSidePanelOpen,
    setSidePanelOpen,
    toggleSidePanel,
    breadcrumbRef,
    StPanel: (stPanelProps: { children?: ReactNode | ReactNode[] }) => (
      <StPanel {...{ isSidePanelOpen, toggleSidePanel, ...stPanelProps }} />
    ),
  };
}
